import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_analytics_total_with_dates, get_grupos } from "../firebase/firestore";
import { RangeOfDatesToArray } from "../static/Utils";
import DateFnsUtils from '@date-io/date-fns';
import { isAfter, format } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, withStyles, Paper, CircularProgress, CssBaseline, Typography, Grid, CardContent, Card, Button, TableRow, TableHead, TableCell, TableContainer, TableBody, Table } from '@material-ui/core';

const useStyles = makeStyles({
    cardTable: {
        maxHeight: 800,
        witdh: '100%',
        alignItems: 'center',
        overflowY: 'scroll'
    },

    tableRows: {
        textAlign: 'center'
    },

    tableCells: {
        textAlign: 'center',
        maxWidth: '100px',
        border: '1px solid white',
        color: 'white',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function AnalyticsUso(props) {
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date(Date.now() - (3600 * 1000 * 24)));
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [analyticsTotalData, setAnalyticsTotalData] = useState(undefined);
    const [gruposData, setGruposData] = useState([]);
    const [datesArray, setDatesArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            await get_grupos().then((result) => {
                setGruposData(result);
                console.log(result);
            })
        };

        fetchData();
        console.log("component updated (analyticsUso)");
    }, [datesArray, analyticsTotalData]);

    const handleDateChange = (type, date) => {
        if (type === 'initial') {
            if (isAfter(date, selectedFinalDate) === false)
                setSelectedInitialDate(date);
        } else {
            setSelectedFinalDate(date);
        }
    }

    const getRpaGrupo = (rpa_identity) => {
        let nome_grupo = "N/D";

        for(let grupo of gruposData) 
        {
            if(grupo.rpas.includes(rpa_identity)) {
                nome_grupo = grupo.nome;
                break;
            }
        }

        return nome_grupo;
    }

    const handleClickOk = async () => {
        setLoading(true);
        setDatesArray(RangeOfDatesToArray(selectedInitialDate, selectedFinalDate))
        await get_analytics_total_with_dates(selectedInitialDate, selectedFinalDate).then((result) => {
            setAnalyticsTotalData(result);
            console.log(result);
        }).finally(() => {
            setLoading(false);
        });

    }

    if (loading === true) {
        return (
            <Grid container alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    let elements_data_head = [];
    if (datesArray !== undefined) {
        for(let date of datesArray) {
            elements_data_head.push(<StyledTableCell key={date+"infoscell2"} className={classes.tableCells}>{format(date, "dd/MM/yyyy")}</StyledTableCell>)
        }
    }

    let rows_processos = [];
    if (analyticsTotalData) 
    {
        let rpasKeys = Object.keys(analyticsTotalData);
        for(let key of rpasKeys) 
        {
            let cells_processos = []
            let processamentos = analyticsTotalData[key];
            for(let date of datesArray) 
            {
                let formatted_date = format(date, "dd/MM/yyyy")
                let processamentos_data = "";
                if (processamentos[formatted_date] !== undefined && processamentos[formatted_date]['processados'] > 0) {
                    processamentos_data = processamentos[formatted_date]['processados']
                }

                let bkground_color = 'green';
                if (processamentos_data === "") {
                    bkground_color = 'red';
                }

                cells_processos.push(
                    <StyledTableCell key={date+"infoscell"} scope="column" className={classes.tableCells} style={{ backgroundColor: bkground_color }}>{processamentos_data}</StyledTableCell>
                );
            }

            let grupo = getRpaGrupo(key)

            rows_processos.push(
                <StyledTableRow key={key+"infos"} className={classes.tableRows}>
                    <StyledTableCell scope="column" style={{ textAlign: 'center', minWidth: '200px', maxWidth: '200px' }}>{key}</StyledTableCell>
                    <StyledTableCell scope="column" style={{ textAlign: 'center', minWidth: '100px', maxWidth: '100px' }}>{grupo}</StyledTableCell>
                    {cells_processos}
                </StyledTableRow>
            );
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />

            <Grid container alignContent="center" alignItems="center" justifyContent="center">
                <Grid item md={6} xs={6} sm={6} lg={6}>
                    <Card raised={false}>
                        <CardContent>
                            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography variant="h5" color="primary" align="center">
                                        Período
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        De:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            value={selectedInitialDate}
                                            onChange={(date) => handleDateChange('initial', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        até
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            value={selectedFinalDate}
                                            onChange={(date) => handleDateChange('final', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                            <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin' }}>
                                <Grid item md={6} xs={6} sm={6} lg={6} style={{ textAlign: 'center' }}>
                                    <Button onClick={() => handleClickOk()} color="primary" variant="contained">
                                        OK
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            
            <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin' }}>
                <Grid item md={10} xs={10} sm={10} lg={10} style={{ textAlign: 'center' }}>
                    { analyticsTotalData && datesArray &&
                    <TableContainer component={Paper} className={classes.cardTable}>
                        <Table size="small" stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{ minWidth: '200px', maxWidth: '200px', textAlign: 'center' }}>RPA</StyledTableCell>
                                    <StyledTableCell style={{ minWidth: '100px', maxWidth: '100px', textAlign: 'center' }}>Grupo</StyledTableCell>
                                    {elements_data_head}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows_processos}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(null, mapDispatchToProps)(AnalyticsUso);
