import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { set_rpa_data, add_log, count_rpas_running_in_host } from '../firebase/firestore';
import { create_rpa_action } from '../firebase/realtime';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import ReplayIcon from '@material-ui/icons/Replay';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 0,
        padding: 0,
        height: '400px',
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        flexDirection: 'column',
    },
    title: {
        magin: 0,
        padding: 0
    },
    item: {
        margin: 0,
        padding: 0
    },
    wrapperButtons: {
        marginTop: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'green',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    iconButton: {
        backgroundColor: 'inherit !important',
        margin: 0,
        padding: 0,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    iconButtonDelete: {
        backgroundColor: 'inherit !important',
        margin: 0,
        padding: 0
    }
}));

export default function InfosRpa(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar()

    useEffect(() => {
        console.log("component updated (infos rpa)");
        setLoading(false);
    }, [props]);

    const handleIniciarRpa = async () => {
        let rpas_running = await count_rpas_running_in_host(props.rpaInfos.hostname);
        if(rpas_running > 0)
        {
            snackbar.showMessage(
                'ATENÇÃO: Sua ação foi cancelada, já exite um RPA em execução na maquina!'
            )
        }
        else
        {
            await handleCreateRpaAction("iniciar");
        }
    };

    const handleCreateRpaAction = async (action) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('iniciar_rpa'))
            return

        setLoading(true);
        await create_rpa_action(props.rpaInfos.doc_id, action, props.rpaInfos.parameters);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - ACTION: " + action)
    };

    const handleChangeRpaParameter = async (key, value) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('iniciar_rpa'))
            return

        setLoading(true);
        var tmp_val = Object.assign({}, props.rpaInfos.parameters);
        tmp_val[key].value = value;
        await set_rpa_data(props.rpaInfos.doc_id, 'parameters', tmp_val);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - CHANGE PARAMETER: " + key + " = " + value)
    };

    const handleChangeRpaResponsavel = async (index, key, value) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('add_responsavel'))
            return

        if (props.rpaInfos.responsavel !== undefined) {
            var tmp_val = Object.assign([], props.rpaInfos.responsavel);
            tmp_val[index][key] = value;
            await set_rpa_data(props.rpaInfos.doc_id, 'responsavel', tmp_val);
            await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - CHANGE RESPONSÁVEL: " + key + " - " + value)
        }
    };

    const handleAddRpaResponsavel = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('add_responsavel'))
            return

        if (props.rpaInfos.responsavel === undefined || Array.isArray(props.rpaInfos.responsavel) === false) {
            props.rpaInfos.responsavel = [];
        }

        props.rpaInfos.responsavel.push({
            nome: "",
            email: ""
        });
        await set_rpa_data(props.rpaInfos.doc_id, 'responsavel', props.rpaInfos.responsavel);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - ADD RESPONSÁVEL")
    };

    const handleDeleteRpaResponsavel = async (index) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('add_responsavel'))
            return

        props.rpaInfos.responsavel.splice(index, 1);
        await set_rpa_data(props.rpaInfos.doc_id, 'responsavel', props.rpaInfos.responsavel);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - DELETE RESPONSÁVEL")
    };

    const handleResetInfos = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('recarregar_infos'))
            return

        await handleCreateRpaAction("update_machine")
	}

    const handleRestartHost = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('reiniciar_host'))
            return

        await handleCreateRpaAction("restart_machine")
	}

    let parametersInitRpa = [];

    if (props.rpaInfos !== undefined && props.rpaInfos.parameters !== undefined) {
        let parametersKeys = Object.keys(props.rpaInfos.parameters)
        parametersKeys = parametersKeys.sort();

        parametersKeys.forEach((key) => {
            let parametersValues = props.rpaInfos.parameters[key].list;
            if (Array.isArray(parametersValues)) {
                parametersInitRpa.push(
                    <Grid item key={key}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={"select-param" + key}>{key}</InputLabel>
                            <NativeSelect
                                value={props.rpaInfos.parameters[key].value}
                                onChange={(event) => handleChangeRpaParameter(key, event.target.value)}
                            >
                                {
                                    parametersValues.map((val) => {
                                        return (<option key={key + val} value={val}>{val}</option>);
                                    })
                                }
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                );
            }
        });
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper className={classes.paper}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            Informações
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button startIcon={<ReplayIcon />} variant="outlined" color="primary" size="small" onClick={() => handleResetInfos()}>
                            Recarregar Parâmetros
                        </Button>
                    </Grid>
                </Grid>
                <Divider light />
                <Container>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                Status:&nbsp;
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color={props.rpaInfos.status === undefined || props.rpaInfos.status === "Parado" ? "secondary" : "primary"} className={classes.title}>
                                {props.rpaInfos.status !== undefined ? props.rpaInfos.status : "Indefinido"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                Hostname:&nbsp;
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                {props.rpaInfos.hostname !== undefined ? props.rpaInfos.hostname : "Indefinido"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                IP Externo:&nbsp;
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                {props.rpaInfos.external_ip !== undefined ? props.rpaInfos.external_ip : "Indefinido"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                IP Interno:&nbsp;
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                {props.rpaInfos.internal_ip !== undefined ? props.rpaInfos.internal_ip : "Indefinido"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                MAC:&nbsp;
                        </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                {props.rpaInfos.mac_address !== undefined ? props.rpaInfos.mac_address : "Indefinido"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '2vmin' }} spacing={2}>
                        <Grid item>
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Responsável
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Adicionar Responsável" style={{ margin: 0, padding: 0 }}>
                                <IconButton aria-label="add responsável" className={classes.iconButton} onClick={() => handleAddRpaResponsavel()}>
                                    <AddCircleIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {props.rpaInfos.responsavel !== undefined && props.rpaInfos.responsavel.length > 0 ?
                        props.rpaInfos.responsavel.map((responsavel, index) => {
                            return (
                                <Grid container key={index + "-responsavel"}  justifyContent="center" alignItems="center" spacing={3} style={{ marginBottom: '2vmin' }}>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="input-email-responsavel">Email</InputLabel>
                                            <Input id="input-email-responsavel" type="email"
                                                value={responsavel.email !== undefined ? responsavel.email : ""}
                                                onChange={(event) => handleChangeRpaResponsavel(index, 'email', event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="input-nome-responsavel">Nome</InputLabel>
                                            <Input id="input-nome-responsavel" type="text"
                                                value={responsavel.nome !== undefined ? responsavel.nome : ""}
                                                onChange={(event) => handleChangeRpaResponsavel(index, 'nome', event.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1} justifyContent="center" alignContent="center" alignItems="center">
                                        <Tooltip title="Remover Responsável" style={{ margin: 0, padding: 0 }}>
                                            <IconButton aria-label="remover responsável" className={classes.iconButtonDelete} onClick={() => handleDeleteRpaResponsavel(index)}>
                                                <DeleteForeverIcon color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>

                                </Grid>
                            );
                        })
                        :
                        <Grid container justifyContent="center" alignItems="center" spacing={3} style={{ marginBottom: '2vmin' }}>
                            <Grid item>
                                <Typography variant="subtitle2" color="inherit">
                                    Nenhum responsável definido
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    <Divider light />
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: '2vmin' }} spacing={2}>
                        <Grid item>
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Ações
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        {parametersInitRpa}
                        <Grid item className={classes.wrapperButtons}>
                            {props.rpaInfos.status !== "Em Execução" ?
                                <Button variant="contained" color="primary" disabled={loading} onClick={() => handleIniciarRpa()}>
                                    Iniciar
                                </Button>
                                :
                                <Button variant="contained" color="primary" disabled={loading} onClick={() => handleCreateRpaAction("parar")}>
                                    Parar
                                </Button>
                            }
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                        {props.user_data.permissions !== undefined && props.user_data.permissions.includes("reiniciar_host") &&
                            <Grid item className={classes.wrapperButtons}>
                                <Button variant="contained" color="primary" disabled={loading} onClick={() => handleRestartHost()}>
                                    Reiniciar Host
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Grid>
                        }
                    </Grid>
                </Container>
            </Paper>
        </React.Fragment>
    );
}