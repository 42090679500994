import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { set_grupo_data, get_all_nomes_rpas_grupos } from '../../firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		padding: theme.spacing(1)
	},
	cardContent: {
		flexGrow: 1,
	},
}));


function AdmGrupos(props) {
	const classes = useStyles();
    const [grupoRpaList, setGrupoRpaList] = useState([]);
    const [rpaList, setRpaList] = useState([]);
    const [loading, setLoading] = useState(true);

	useEffect(() => {
        const fetchData = async () => {
            await get_all_nomes_rpas_grupos().then((rpas) => {
                setRpaList(rpas);
                setLoading(false);
            });
        };
        fetchData();

        if(props.selectedGrupo.rpas !== undefined) {
            props.selectedGrupo.rpas.sort();
            setGrupoRpaList(props.selectedGrupo.rpas);
        } else {
            setGrupoRpaList([]);
        }

		console.log('component updated (adm grupos)')
	}, [props]);

	const handleAddFromRpaList = (rpa_id) => {
        let tmp_rpa_list = Object.assign([], grupoRpaList);
        if (!tmp_rpa_list.includes(rpa_id)) {
            tmp_rpa_list.push(rpa_id);  
            
            set_grupo_data(props.selectedGrupo.doc_id, 'rpas', tmp_rpa_list);
            setGrupoRpaList(tmp_rpa_list);
            props.selectedGrupo.rpas = tmp_rpa_list;
        }
	};

	const handleRemoveFromRpaGrupos = (rpa_id) => {
        let tmp_rpa_list = Object.assign([], grupoRpaList);
        if (tmp_rpa_list.includes(rpa_id)) {
            let idx = tmp_rpa_list.indexOf(rpa_id);
            tmp_rpa_list.splice(idx, 1);
            
            set_grupo_data(props.selectedGrupo.doc_id, 'rpas', tmp_rpa_list);
            setGrupoRpaList(tmp_rpa_list);
            props.selectedGrupo.rpas = tmp_rpa_list;
        }
    };
    
    if (loading === true) {
		return (
			<Grid container alignContent="center" alignItems="center" justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<React.Fragment>
			<CssBaseline />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                RPAs Disponíveis
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {rpaList.length === 0 &&
                            <Typography variant="subtitle1" color="primary">
                                Nenhum RPA encontrado.
                            </Typography>
                        }
                        {rpaList.map((rpa) => {
                            return (
                                <Grid item key={rpa}>
                                    <Chip
                                        label={rpa}
                                        deleteIcon={<AddCircleIcon />}
                                        onDelete={() => handleAddFromRpaList(rpa)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                RPAs do Grupo
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {grupoRpaList === undefined || grupoRpaList.length === 0 ?
                            <Typography variant="subtitle1" color="primary">
                                Nenhum acesso a RPA encontrado.
                            </Typography>
                        : grupoRpaList.map((rpa_id, idx) => {
                            return (
                                <Grid item key={rpa_id + idx}>
                                    <Chip
                                        label={rpa_id}
                                        onDelete={() => handleRemoveFromRpaGrupos(rpa_id)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </Card>
		</React.Fragment>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
	};
}

export default connect(mapDispatchToProps)(AdmGrupos);
