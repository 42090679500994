import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { stream_get_rpa_by_id } from '../firebase/firestore';
import LogsRpa from './LogsRpa';
import InfosRpa from './InfosRpa';
import ChartRpa from './ChartRpa';
import ScheduleRpa from './ScheduleRpa';

const useStyles = makeStyles((theme) => ({
    containerButtons: {
        margin: 0,
        marginBottom: theme.spacing(3),
        padding: 0
    },
    title: {
        magin: 0,
        padding: 0
    }
}));

function RpaDashboard(props) {
    const classes = useStyles();
    const [rpaInfos, setRpaInfos] = useState(undefined);

    useEffect(() => {
        const stream_rpa_infos = stream_get_rpa_by_id(props.selected_rpa.doc_id, {
            next: querySnapshot => {
                let rpa_data = {
                    ...querySnapshot.data(),
                    doc_id: querySnapshot.id
                }
                setRpaInfos(rpa_data);
                props.setRpa("page_title", "DASHBOARD " + props.selected_rpa.identity.toUpperCase());
            }
        });
        
        console.log("component update (dashboard)")
        return stream_rpa_infos;
    }, [setRpaInfos, props]);

	const handleChangeSelectedRpa = (rpa) => {
		props.setRpa("selected_rpa", rpa);
    };
    
    if(rpaInfos === undefined) {
        return (
            <Grid container alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start" className={classes.containerButtons}>
                <Grid item md={12}>
                    <Button variant="contained" color="primary" onClick={() => handleChangeSelectedRpa(undefined)}>
                        Voltar
                    </Button>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <LogsRpa rpaInfos={rpaInfos} user_data={props.user_data}/>
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <InfosRpa rpaInfos={rpaInfos} user_data={props.user_data}/>
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <ChartRpa rpaInfos={rpaInfos} user_data={props.user_data}/>
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <ScheduleRpa rpaInfos={rpaInfos} user_data={props.user_data}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
	return {
        selected_rpa: state.rpa.selected_rpa,
        user_data: state.rpa.user_data
	};
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RpaDashboard);
  