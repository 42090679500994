import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../actions";
import { withStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import DialogForgotPassword from "../components/dialogs/DialogForgotPassword";

const styles = () => ({
	"@global": {
		body: {
			backgroundColor: "#fff"
		}
	},
	paper: {
		marginTop: 100,
		display: "flex",
		padding: 20,
		flexDirection: "column",
		alignItems: "center"
	},
	avatar: {
		marginLeft: "auto",
		marginRight: "auto",
		backgroundColor: "#f50057"
	},
	form: {
		marginTop: 1
	},
	errorText: {
		color: "#f50057",
		marginBottom: 5,
		textAlign: "center"
	}
});

class Login extends Component {
	state = { email: "", password: "", dialogResetPasswordOpen: false};

	handleOpenCloseDialogResetPassword = (state) => {
        this.setState({ dialogResetPasswordOpen: state });
    }

	handleEmailChange = ({ target }) => {
		this.setState({ email: target.value });
	};

	handlePasswordChange = ({ target }) => {
		this.setState({ password: target.value });
	};

	handleSubmit = () => {
		const { dispatch } = this.props;
		const { email, password } = this.state;

		dispatch(loginUser(email, password));
	};

	render() {
		const { classes, loginError, isLoggingIn, isAuthenticated } = this.props;
		if (isAuthenticated) {
			return <Redirect to="/" />;
		} else {
			return (
				<Container component="main" maxWidth="xs">
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Logar
						</Typography>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="email"
							label="Email"
							name="email"
							onChange={this.handleEmailChange}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							name="password"
							label="Senha"
							type="password"
							id="password"
							onChange={this.handlePasswordChange}
						/>
						{loginError && (
							<Grid container style={{padding: '1vmin'}}>
								<Grid container alignContent="center" alignItems="center" justifyContent="center">
									<Typography className={classes.errorText}>
										Login incorreto!
									</Typography>
								</Grid>
							</Grid>
						)}
						{isLoggingIn && (
							<Grid container style={{padding: '1vmin'}}>
								<Grid container alignContent="center" alignItems="center" justifyContent="center">
									<Typography>
										Logando...
									</Typography>
								</Grid>
								<Grid container alignContent="center" alignItems="center" justifyContent="center">
									<CircularProgress size={20} />
								</Grid>
							</Grid>
						)}
						<Button
							type="button"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							onClick={this.handleSubmit}
							style={{marginTop: '2vmin'}}
						>
							Login
						</Button>

						<Grid container style={{marginTop: '1vmin'}}>
							<Grid item xs>
								<Link variant="body2" onClick={() => this.handleOpenCloseDialogResetPassword(true)}>
									Esqueceu a senha ?
								</Link>
							</Grid>
						</Grid>
					</Paper>
					<DialogForgotPassword open={this.state.dialogResetPasswordOpen} handleClose={(state) => this.handleOpenCloseDialogResetPassword(state)}/>
				</Container>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		isLoggingIn: state.auth.isLoggingIn,
		loginError: state.auth.loginError,
		isAuthenticated: state.auth.isAuthenticated
	};
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
