import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { set_user_data, get_rpa_names_list } from '../../firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		padding: theme.spacing(1)
	},
	cardContent: {
		flexGrow: 1,
	},
}));


function AdmUserRpa(props) {
	const classes = useStyles();
    const [userRpaList, setUserRpaList] = useState([]);
    const [rpaList, setRpaList] = useState([]);
    const [loading, setLoading] = useState(true);

	useEffect(() => {
        const fetchData = async () => {
            await get_rpa_names_list().then((rpas) => {
                setRpaList(rpas);
                setLoading(false);
            });
        };
        fetchData();

        if(props.selectedUser.rpas_acesso !== undefined) {
            props.selectedUser.rpas_acesso.sort();
            setUserRpaList(props.selectedUser.rpas_acesso);
        } else {
            setUserRpaList([]);
        }

		console.log('component updated (adm user rpa)')
	}, [props]);

	const handleAddFromRpaList = (rpa_id) => {
        let tmp_rpa_list = Object.assign([], userRpaList);
        if (!tmp_rpa_list.includes(rpa_id)) {
                tmp_rpa_list.push(rpa_id);
            
            props.selectedUser.rpas_acesso = tmp_rpa_list;
            set_user_data(props.selectedUser.doc_id, 'rpas_acesso', tmp_rpa_list);
            setUserRpaList(tmp_rpa_list);
        }
	};

	const handleRemoveFromRpaUsers = (rpa_id) => {
        let tmp_rpa_list = Object.assign([], userRpaList);
        if (tmp_rpa_list.includes(rpa_id)) {
            let idx = tmp_rpa_list.indexOf(rpa_id);
            tmp_rpa_list.splice(idx, 1);

            props.selectedUser.rpas_acesso = tmp_rpa_list;
            set_user_data(props.selectedUser.doc_id, 'rpas_acesso', tmp_rpa_list);
            setUserRpaList(tmp_rpa_list);
        }
    };
    
    if (loading === true) {
		return (
			<Grid container alignContent="center" alignItems="center" justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<React.Fragment>
			<CssBaseline />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                RPAs Disponíveis
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {rpaList.length === 0 &&
                            <Typography variant="subtitle1" color="primary">
                                Nenhum RPA encontrado.
                            </Typography>
                        }
                        {rpaList.map((rpa) => {
                            return (
                                <Grid item key={rpa}>
                                    <Chip
                                        label={rpa}
                                        deleteIcon={<AddCircleIcon />}
                                        onDelete={() => handleAddFromRpaList(rpa)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                RPAs Permitidos
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {userRpaList === undefined || userRpaList.length === 0 ?
                            <Typography variant="subtitle1" color="primary">
                                Nenhum acesso a RPA encontrado.
                            </Typography>
                        : userRpaList.map((rpa_id, idx) => {
                            return (
                                <Grid item key={rpa_id + idx}>
                                    <Chip
                                        label={rpa_id}
                                        onDelete={() => handleRemoveFromRpaUsers(rpa_id)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </Card>
		</React.Fragment>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
	};
}

export default connect(mapDispatchToProps)(AdmUserRpa);
