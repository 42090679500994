import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { get_rpa_list_by_user_and_host, get_host_list_by_user, set_rpa_data } from "../firebase/firestore";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardContent: {
        flexGrow: 1,
    },
    bgParado: {
        background: 'linear-gradient(45deg, rgba(242, 22, 59, 0.2) 30%, rgba(255, 0, 0, 0.25) 90%)',
        boxShadow: '0 3px 5px 2px rgba(255, 38, 0, .3)',
    },
    bgAtivo: {
        background: 'linear-gradient(45deg, rgba(66, 237, 143, 0.2) 30%, rgba(0, 255, 4, 0.2) 90%)',
        boxShadow: '0 3px 5px 2px rgba(0, 235, 4, .3)',
    }
}));

function RpaList(props) {
    const classes = useStyles();
    const [rpaList, setRpaList] = useState([]);
    const [hostList, setHostList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDisabled, setShowDisabled] = useState(false);
    const [random, setRandom] = useState(Math.random());

    const handleChangeSelectedRpa = (rpa) => {
        props.setRpa("selected_rpa", rpa);
    };

    const handleChangeSelectedHost = (host) => {
        props.setRpa("selected_host", host);
        setLoading(true);
    };

    const handleDisableEnableRpa = (rpa, value) => {
        set_rpa_data(rpa.doc_id, 'disabled', value);
        setRandom(Math.random());
    };

	useEffect(() => {
		const fetchData = async () => {
            await get_host_list_by_user(props.user).then((hosts) => {
                setHostList(hosts);
            });
            
            await get_rpa_list_by_user_and_host(props.user, props.selected_host).then((data) => {
                setRpaList(data);
            });

            setLoading(false);
		};

		fetchData();
		console.log("component updated (rpaList)");
    }, [props, random]);

	if(loading === true) {
        return (
            <Grid container alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    let list_rpas = []
    for(let rpa of rpaList) {
        if(rpa.disabled !== undefined && rpa.disabled === true && showDisabled === false)
            continue

        list_rpas.push(
            <Grid item key={rpa.doc_id} xs={6} sm={6} md={3}>
                <Card className={clsx(classes.card, {
                        [classes.bgParado]: rpa.status !== "Em Execução",
                        [classes.bgAtivo]: rpa.status === "Em Execução"
                    })}>
                    <CardContent className={classes.cardContent}>
                        <Grid container alignContent="center" alignItems="center" justifyContent="center">
                            <Typography variant="h5" style={{ fontFamily: 'Helvetica Narrow, sans-serif'}}>
                                {rpa.identity}
                            </Typography>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container alignContent="center" alignItems="center" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={() => handleChangeSelectedRpa(rpa)}>
                                Ver
                            </Button>
                        </Grid>
                        {props.user_data !== undefined && props.user_data.permissions !== undefined && props.user_data.permissions.includes("desabilitar_rpas") ?
                            rpa.disabled === undefined || rpa.disabled === false ?
                                <Tooltip title="Desabilitar RPA">
                                    <IconButton onClick={() => handleDisableEnableRpa(rpa, true)}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                </Tooltip>
                            :
                                <Tooltip title="Habilitar RPA">
                                    <IconButton onClick={() => handleDisableEnableRpa(rpa, false)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                        : undefined }
                    </CardActions>
                </Card>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container>
                <Grid item md={12}>
                    <Card variant="outlined" raised={false}>
                        <CardContent>
                            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={3}>
                                <Grid item>
                                    <Typography variant="h6" color="primary">
                                        Selecione o host:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth={true}>
                                        <Select
                                            id="select-contas"
                                            value={props.selected_host === undefined || hostList.length <= 0 ? 'selecione' : props.selected_host}
                                            onChange={(event) => handleChangeSelectedHost(event.target.value)}
                                        >
                                            <MenuItem value={'selecione'} disabled>Selecionar...</MenuItem>
                                            {
                                                hostList.map((host) => {
                                                    return (<MenuItem key={host.doc_id} value={host.doc_id}>{host.apelido} ({host.doc_id})</MenuItem>);
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    {props.user_data !== undefined && props.user_data.permissions !== undefined && props.user_data.permissions.includes("desabilitar_rpas") ?
                                        showDisabled === false ?
                                            <Tooltip title="Ver RPA's desabilitados">
                                                <IconButton onClick={() => setShowDisabled(true)}>
                                                    <SpeakerNotesIcon />
                                                </IconButton>
                                            </Tooltip>
                                        :
                                            <Tooltip title="Esconder RPA's desabilitados">
                                                <IconButton onClick={() => setShowDisabled(false)}>
                                                    <SpeakerNotesOffIcon />
                                                </IconButton>
                                            </Tooltip>
                                    : undefined }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            { list_rpas.length === 0 ? 
                <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '2vmin' }}>
                    <Typography variant="h4" color="primary">
                        Nenhum RPA para mostrar.
                    </Typography>
                </Grid>
                :
                <Grid container spacing={3} style={{ marginTop: '2vmin' }}>
                    {list_rpas}
                </Grid>
            }
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value }),
    };
}

function mapStateToProps(state) {
	return {
        selected_rpa: state.rpa.selected_rpa,
        selected_host: state.rpa.selected_host,
		user_data: state.rpa.user_data,
		user: state.auth.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RpaList);