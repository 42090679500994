import React from 'react';
import { connect } from "react-redux";
import { Switch } from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import { staticMenu } from '../static/Menus';
import { Route } from "react-router-dom";
import NavBar from '../components/NavBar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import Grid from '@material-ui/core/Grid';
import { SnackbarProvider } from 'material-ui-snackbar-provider'

const useStyles = makeStyles(theme => ({
    mainViews: {
        overflowX: 'hidden',
    },
    navbar: {
        marginBottom: theme.spacing(1),
    },
    mainContainer: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(6)
    }
}));

function Routing(props) {
    const classes = useStyles();
    const { isAuthenticated, isVerifying } = props;
    let routes = [];

    staticMenu.forEach((menu) => {
        if (menu.path === "/login")
            routes.push(<Route key={menu} exact={menu.exact} path={menu.path} component={menu.component} />);
        else
            routes.push(<ProtectedRoute key={menu} exact={menu.exact} path={menu.path} component={menu.component} isAuthenticated={isAuthenticated} isVerifying={isVerifying} />);
    });

    return (
        <React.Fragment>
            <CssBaseline />
            <main className={classes.mainViews}>
                <ConnectedRouter history={props.history}>
                    <SnackbarProvider SnackbarProps={{ autoHideDuration: 5000 }}>
                        {isAuthenticated === true &&
                            <Grid container className={classes.navbar}>
                                <NavBar />
                            </Grid>
                        }
                        <Grid container className={classes.mainContainer}>
                            <Switch>
                                {routes}
                            </Switch>
                        </Grid>
                    </SnackbarProvider>
                </ConnectedRouter>
            </main>
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
        user_data: state.rpa.user_data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routing);