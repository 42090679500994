import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ignore_values } from '../../static/Schedule';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function DialogIgnoreDateSchedule(props) {
    const [ignoreDays, setIgnoreDays] = useState([]);
    const [index, setIndex] = useState(undefined);

    useEffect(() => {
        if(props.ignoreDays !== undefined) {
            setIgnoreDays(props.ignoreDays);
        } else {
            setIgnoreDays([]);
        }

        if(props.index !== undefined) {
            setIndex(props.index);
        } else {
            setIndex(undefined);
        }

        console.log("component updated (dialog ignore date schedule)");
    }, [props.ignoreDays, props.index]);

    const handleClose = () => {
        props.handleClose(index, ignoreDays);
    };

    function checkDate(name) {
        if(ignoreDays.includes(name)) {
            return true;
        }
        else
        {
            return false;
        }
    }

    const handleChangeDateList = (name, checked) => {
        let tmp_date_list = Object.assign([], ignoreDays);
        if(checked) {
            if (!tmp_date_list.includes(name)) {
                tmp_date_list.push(name);
            }
        } 
        else 
        {
            if (tmp_date_list.includes(name)) {
                let idx = tmp_date_list.indexOf(name);
                tmp_date_list.splice(idx, 1);
            }
        }

        setIgnoreDays(tmp_date_list);
    }

    return (
        <Dialog aria-labelledby="dialog ignore date schedule" open={props.open}>
            <DialogTitle>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    Ignorar Dias - Agendamento
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item md={12}>
                        <FormGroup row>
                                {ignore_values.map((item) => {
                                    return (
                                        <FormControlLabel key={item.title}
                                            control={<Checkbox checked={checkDate(item.value)} onChange={(event) => handleChangeDateList(item.value, event.target.checked)} name={item.title} />}
                                            label={item.title}
                                        />
                                        );
                                    })
                                }
                        </FormGroup>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );

}