import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { stream_get_users_list, stream_grupos_list } from '../firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import AdmUser from "../components/admin/AdmUser";
import AdmUserRpa from "../components/admin/AdmUserRpa";
import AdmUserHosts from "../components/admin/AdmUserHosts";
import AdmUserLogs from "../components/admin/AdmUserLogs";
import AdmGrupos from "../components/admin/AdmGrupos";
import DialogRegisterUser from "../components/dialogs/DialogRegisterUser";
import DialogAddGrupo from "../components/dialogs/DialogAddGrupo";

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%'
	},
	cardContent: {
		flexGrow: 1,
	},
	containerButtons: {
        margin: 0,
        marginBottom: theme.spacing(1),
        padding: 0
    },
}));


function Administracao(props) {
	let history = useHistory();
	const classes = useStyles();
	const [userList, setUserList] = useState([]);
	const [gruposList, setGruposList] = useState([]);
	const [selectedGrupo, setSelectedGrupo] = useState(undefined);
	const [selectedUser, setSelectedUser] = useState(undefined);
	const [dialogNewUserOpen, setDialogNewUserOpen] = useState(false);
	const [dialogAddGrupoOpen, setDialogAddGrupoOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const stream_users_list =
			stream_get_users_list({
				next: querySnapshot => {
					let users = [];
					querySnapshot.forEach(doc => {
						let user = {
							...doc.data(),
							doc_id: doc.id
						}
						users.push(user)
					})
					setUserList(users);
				}
			});

		console.log('component updated (admin)')
		return () => stream_users_list();
	}, [props, setUserList]);

	useEffect(() => {
		const stream_users_list =
			stream_grupos_list({
				next: querySnapshot => {
					let grupos = [];
					querySnapshot.forEach(doc => {
						let grupo = {
							...doc.data(),
							doc_id: doc.id
						}
						grupos.push(grupo)
					})
					setGruposList(grupos);
					setLoading(false);
					props.setRpa("page_title", "Administração");
				}
			});

		console.log('component updated (admin 2)')
		return () => stream_users_list();
	}, [props, setGruposList]);

	const handleOpenCloseDialogNewUser = (state) => {
        setDialogNewUserOpen(state);
    }

	const handleOpenCloseDialogAddGrupo = (state) => {
        setDialogAddGrupoOpen(state);
    }

	const handleChangeUser = (user) => {
		setSelectedUser(user);
	};

	const handleChangeGrupo = (grupo) => {
		setSelectedGrupo(grupo);
	};

	const handleRedirect = (to) => {
        history.push(to)
	}

	if (loading === true || props.user_data.permissions === undefined || !props.user_data.permissions.includes("pagina_adm")) {
		return (
			<Grid container alignContent="center" alignItems="center" justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<Grid container justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start" className={classes.containerButtons}>
                <Grid item md={12}>
                    <Button variant="contained" color="primary" onClick={() => handleRedirect("/")}>
                        Voltar
                    </Button>
                </Grid>
            </Grid>

			<Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={3} className={classes.container}>
				<Grid item>
					<Typography variant="h5" color="primary">
						USER
					</Typography>
				</Grid>
				<Grid item md={12}>
					<Card className={classes.card}>
						<CardContent className={classes.cardContent}>
							<Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
								<Grid item>
									<Typography variant="h6" color="primary">
										Selecione uma conta:
									</Typography>
								</Grid>
								<Grid item md={3}>
									<FormControl fullWidth={true}>
										<Select
											id="select-contas"
											value={selectedUser === undefined ? 'selecione' : selectedUser}
											onChange={(event) => handleChangeUser(event.target.value)}
										>
											<MenuItem value={'selecione'} disabled>Selecionar...</MenuItem>
											{
												userList.map((user) => {
													return (<MenuItem key={user.doc_id} value={user}>{user.email}</MenuItem>);
												})
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item>
									<Button variant="contained" color="primary" onClick={() => handleOpenCloseDialogNewUser(true)}>
										Cadastrar Usuário
									</Button>
								</Grid>
								<DialogRegisterUser open={dialogNewUserOpen} handleClose={handleOpenCloseDialogNewUser}/>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				{selectedUser !== undefined &&
					<React.Fragment>
						<Grid item md={12}>
							<AdmUser selectedUser={selectedUser} />
						</Grid>
						<Grid item md={12}>
							<AdmUserRpa selectedUser={selectedUser} />
						</Grid>
						<Grid item md={12}>
							<AdmUserHosts selectedUser={selectedUser} />
						</Grid>
						<Grid item md={12}>
							<AdmUserLogs selectedUser={selectedUser} />
						</Grid>
					</React.Fragment>
				}
			</Grid>

			<Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={3} className={classes.container} style={{ marginTop: '4vmin' }}>
				<Grid item>
					<Typography variant="h5" color="primary">
						GRUPOS
					</Typography>
				</Grid>
				<Grid item md={12}>
					<Card className={classes.card}>
						<CardContent className={classes.cardContent}>
							<Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
								<Grid item>
									<Typography variant="h6" color="primary">
										Selecione um Grupo
									</Typography>
								</Grid>
								<Grid item md={3}>
									<FormControl fullWidth={true}>
										<Select
											id="select-contas"
											value={selectedGrupo === undefined ? 'selecione' : selectedGrupo}
											onChange={(event) => handleChangeGrupo(event.target.value)}
										>
											<MenuItem value={'selecione'} disabled>Selecionar...</MenuItem>
											{
												gruposList.map((grupo) => {
													return (<MenuItem key={grupo.doc_id} value={grupo}>{grupo.nome}</MenuItem>);
												})
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item>
									<Button variant="contained" color="primary" onClick={() => handleOpenCloseDialogAddGrupo(true)}>
										Cadastrar Grupo
									</Button>
								</Grid>
								<DialogAddGrupo open={dialogAddGrupoOpen} handleClose={handleOpenCloseDialogAddGrupo}/>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				{selectedGrupo !== undefined &&
					<React.Fragment>
						<Grid item md={12}>
							<AdmGrupos selectedGrupo={selectedGrupo} />
						</Grid>
					</React.Fragment>
				}
			</Grid>

		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return {
		user_data: state.rpa.user_data
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Administracao);
