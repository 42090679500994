import { Firebase } from './firebase';
import { Timestamp } from "firebase/firestore";
import { ref, set, child, push } from "firebase/database";

export async function create_rpa_action(id_rpa, action, parameters) {
    const newKey = push(child(ref(Firebase), 'actions')).key;

    await set(ref(Firebase, 'actions/' + newKey), {
        rpa_doc_id: id_rpa,
        action: action,
        action_time: Timestamp.fromDate(new Date()),
        action_parameters: parameters
    });
}