import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { set_rpa_data, add_log } from '../firebase/firestore';
import { create_rpa_action } from '../firebase/realtime';
import { Timestamp } from "firebase/firestore";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CssBaseline from '@material-ui/core/CssBaseline';
import { repeat_values, action_values } from '../static/Schedule';
import Button from '@material-ui/core/Button';
import DialogIgnoreDateSchedule from './dialogs/DialogIgnoreDateSchedule';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        margin: 0,
        padding: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '400px',
    },
    paperSchedule: {
        margin: 0,
        padding: theme.spacing(1),
        overflow: 'hidden',
    },
    title: {
        magin: 0,
        padding: 0
    },
    loading: {
        color: 'green',
        marginTop: theme.spacing(2),
    },
    containerSchedules: {
        padding: theme.spacing(1),
        height: '100%'
    },
    itemContainerSchedules: {
        marginTop: '1vmin'
    },
    formControl: {
        width: '100%',
    },
    iconButton: {
        backgroundColor: 'inherit !important',
        margin: 0,
        padding: 0,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    iconButtonDelete: {
        backgroundColor: 'inherit !important',
        margin: 0,
        padding: 0,
        marginTop: theme.spacing(4),
    }
}));

export default function ScheduleRpa(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [dialogIgnoreDateScheduleOpen, setDialogIgnoreDateScheduleOpen] = useState([]);

    useEffect(() => {
        console.log("component updated (schedule rpa)");
        setLoading(false);
    }, [props]);

    const handleOpenDialogIgnoreDateSchedule = (index) => {
        if(index !== undefined) {
            let tmp_open = Object.assign([], dialogIgnoreDateScheduleOpen);
            tmp_open.push(index);
            setDialogIgnoreDateScheduleOpen(tmp_open);
        }
    }

    const handleCloseDialogIgnoreDateSchedule = (index, ignore_days) => {
        if(ignore_days !== undefined && index !== undefined) {
            let tmp_open = Object.assign([], dialogIgnoreDateScheduleOpen);
            let idx = tmp_open.indexOf(index);
            tmp_open.splice(idx, 1);
            setDialogIgnoreDateScheduleOpen(tmp_open);
            if(ignore_days !== props.rpaInfos.scheduled_actions[index].ignore_days)
                handleChangeRpaSchedule(index, 'ignore_days', ignore_days)
        }
    }

    const handleCreateRpaAction = async (action) => {
        await create_rpa_action(props.rpaInfos.doc_id, action, props.rpaInfos.parameters);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - ACTION: " + action)
    };

    const handleAddRpaScheduleAction = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('menu_agendamento'))
            return

        setLoading(true);
        if (props.rpaInfos.scheduled_actions === undefined) {
            props.rpaInfos.scheduled_actions = [];
        }

        props.rpaInfos.scheduled_actions.push({
            action_parameters: Object.assign({}, props.rpaInfos.parameters),
            action: action_values[0].value,
            start_date: Timestamp.fromDate(new Date()),
            last_run: Timestamp.fromDate(new Date()),
            repeat: repeat_values[0].value,
            time_x_minutes: 0,
            ignore_days: []
        });

        await set_rpa_data(props.rpaInfos.doc_id, 'scheduled_actions', props.rpaInfos.scheduled_actions);
        await handleCreateRpaAction("update_scheduled_actions")
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - ADD SCHEDULE")
    };

    const handleDeleteRpaScheduleAction = async (index) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('menu_agendamento'))
            return

        setLoading(true);
        props.rpaInfos.scheduled_actions.splice(index, 1);
        await set_rpa_data(props.rpaInfos.doc_id, 'scheduled_actions', props.rpaInfos.scheduled_actions);
        await handleCreateRpaAction("update_scheduled_actions")
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - DELETE SCHEDULE")
    };

    const handleChangeRpaScheduleParameter = async (index, key, value) => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('menu_agendamento'))
            return

        setLoading(true);
        var tmp_val = Object.assign([], props.rpaInfos.scheduled_actions);
        tmp_val[index].action_parameters[key].value = value;
        await set_rpa_data(props.rpaInfos.doc_id, 'scheduled_actions', tmp_val);
        await handleCreateRpaAction("update_scheduled_actions")
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - CHANGE SCHEDULE PARAMETER")
    };

    const handleChangeRpaSchedule = async (index, key, value) => {
        if (value === undefined || value.toString() === "Invalid Date" || props.user_data.permissions === undefined || !props.user_data.permissions.includes('menu_agendamento'))
            return

        setLoading(true);
        var tmp_val = Object.assign([], props.rpaInfos.scheduled_actions);
        if (value instanceof Date) {
            tmp_val[index][key] = Timestamp.fromDate(value);
        }
        else {
            tmp_val[index][key] = value;
        }

        await set_rpa_data(props.rpaInfos.doc_id, 'scheduled_actions', props.rpaInfos.scheduled_actions);
        await handleCreateRpaAction("update_scheduled_actions")
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - CHANGE SCHEDULE")
        setLoading(false);
    };

    const getParametersSchedule = (scheduled_action, index) => {
        let parametersInitRpa = [];
        if (scheduled_action.action_parameters !== undefined) {
            let parametersKeys = Object.keys(scheduled_action.action_parameters)
            parametersKeys = parametersKeys.sort();

            parametersKeys.forEach((key) => {
                let parametersValues = scheduled_action.action_parameters[key].list;
                if (Array.isArray(parametersValues)) {
                    parametersInitRpa.push(
                        <Grid item key={key + "-schedule"} xs={6} md={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor={"select-param-action" + key}>{key}</InputLabel>
                                <Select
                                    value={scheduled_action.action_parameters[key].value}
                                    onChange={(event) => handleChangeRpaScheduleParameter(index, key, event.target.value)}
                                >
                                    {
                                        parametersValues.map((val) => {
                                            return (<MenuItem key={key + val + "-schedule"} value={val}>{val}</MenuItem>);
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    );
                }
            });
        }
        return parametersInitRpa;
    }

    let scheduledActions = [];
    if (props.rpaInfos !== undefined && props.rpaInfos.scheduled_actions !== undefined && props.rpaInfos.scheduled_actions.length > 0) {
        props.rpaInfos.scheduled_actions.forEach((scheduled_action, index) => {
            scheduledActions.push(
                <Grid item key={index + "-scheduled-action"} md={4}>
                    <Paper className={classes.paperSchedule}>
                        <Grid container justifyContent="center" alignContent="center" alignItems="center">
                            <Grid item xs={12} md={12}>
                                <KeyboardDateTimePicker
                                    label="Data Inicio"
                                    variant="inline"
                                    ampm={false}
                                    value={scheduled_action.start_date !== undefined ? new Date(scheduled_action.start_date.toDate()) : new Date()}
                                    onChange={(value) => handleChangeRpaSchedule(index, 'start_date', value)}
                                    format="dd/MM/yyyy HH:mm"
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" alignContent="center" alignItems="center" className={classes.itemContainerSchedules}>
                            <Grid item xs={12} md={12}>
                                <KeyboardDateTimePicker
                                    label="Ultima Execução"
                                    variant="inline"
                                    ampm={false}
                                    value={scheduled_action.last_run !== undefined ? new Date(scheduled_action.last_run.toDate()) : new Date()}
                                    onChange={(value) => handleChangeRpaSchedule(index, 'last_run', value)}
                                    format="dd/MM/yyyy HH:mm"
                                />
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={2} className={classes.itemContainerSchedules}>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="select-repeat">Repetir</InputLabel>
                                    <Select
                                        id="select-repeat"
                                        value={scheduled_action.repeat !== undefined ? scheduled_action.repeat : repeat_values[0].value}
                                        onChange={(event) => handleChangeRpaSchedule(index, 'repeat', event.target.value)}
                                    >
                                        {
                                            repeat_values.map((repeat) => {
                                                return (<MenuItem key={repeat} value={repeat.value}>{repeat.title}</MenuItem>);
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel htmlFor="select-action">Ação</InputLabel>
                                    <Select
                                        id="select-action"
                                        value={scheduled_action.action !== undefined ? scheduled_action.action : action_values[0].value}
                                        onChange={(event) => handleChangeRpaSchedule(index, 'action', event.target.value)}
                                    >
                                        {
                                            action_values.map((action) => {
                                                return (<MenuItem key={action} value={action.value}>{action.title}</MenuItem>);
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {scheduled_action.repeat !== undefined && scheduled_action.repeat === "every x minutes" &&
                            <Grid container justifyContent="center" alignContent="center" alignItems="center" className={classes.itemContainerSchedules}>
                                <Grid item xs={12} md={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Tempo X</InputLabel>
                                    <Input type="number" value={scheduled_action.time_x_minutes !== undefined ? scheduled_action.time_x_minutes : 120} onChange={ (event) => handleChangeRpaSchedule(index, 'time_x_minutes', event.target.value) }/>
                                </FormControl>
                                </Grid>
                            </Grid>        
                        }

                        <Grid container justifyContent="center" alignContent="center" alignItems="center" className={classes.itemContainerSchedules}>
                            <Button variant="outlined" size="small" color="primary" onClick={ () => handleOpenDialogIgnoreDateSchedule(index) }>
                                Ignorar Dias
                            </Button>
                            <DialogIgnoreDateSchedule index={index} ignoreDays={scheduled_action.ignore_days} open={dialogIgnoreDateScheduleOpen.includes(index) ? true : false} handleClose={handleCloseDialogIgnoreDateSchedule}/>
                        </Grid>

                        <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={2} className={classes.itemContainerSchedules}>
                            {getParametersSchedule(scheduled_action, index)}
                        </Grid>

                        <Grid container justifyContent="center" alignContent="center" alignItems="center">
                            <Tooltip title="Remover Agendamento">
                                <IconButton aria-label="remove agendamento" onClick={() => handleDeleteRpaScheduleAction(index)} className={classes.iconButtonDelete}>
                                    <DeleteForeverIcon color="error" fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Paper>
                </Grid>
            );
        });
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper className={classes.paperContainer}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            Agendamento
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Adicionar Agendamento" style={{margin: 0, padding: 0}}>
                            <IconButton aria-label="add agendamento" className={classes.iconButton} onClick={() => handleAddRpaScheduleAction()}>
                                <AddCircleIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Divider light />
                <Container>
                    <Grid container justifyContent="center" alignItems="center" alignContent="center" className={classes.containerSchedules} spacing={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            {loading ? <CircularProgress size={24} className={classes.loading} /> : scheduledActions}
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Container>
            </Paper >
        </React.Fragment>
    );
}