import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { get_user_data_by_user } from '../firebase/firestore';
import { alter_password } from '../firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%'
	},
	cardContent: {
		flexGrow: 1,
	}
}));


function MinhaConta(props) {
    const classes = useStyles();
    const snackbar = useSnackbar()
    const [userData, setUserData] = useState(undefined);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [loading, setLoading] = useState(true);

	useEffect(() => {
        const fetchData = async () => {
            props.setRpa("page_title", "MINHA CONTA");
            await get_user_data_by_user(props.user).then((user_data) => {
                setUserData(user_data);
                setLoading(false);
            });
        };

        fetchData();
	}, [props]);

    const handleAlterPassword = async () => {
        if(newPassword.length < 6 || newPassword.length > 20) {
            return snackbar.showMessage(
                'Digite uma senha válida (>= 6 caracteres).'
            )
        }

        if(newPassword !== confirmNewPassword) {
            return snackbar.showMessage(
                'As senhas não conferem.'
            )
        }

        await alter_password(newPassword).then(() => {
            snackbar.showMessage(
                'Senha alterada com sucesso!'
            )
        })

    }

    if (loading === true || userData === undefined) {
		return (
			<Grid container alignContent="center" alignItems="center" justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<React.Fragment>
			<CssBaseline />
            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={3} className={classes.container}>
				<Grid item md={12}>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <React.Fragment>                                
                                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                                    <Grid item md={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="input-email-conta">Email</InputLabel>
                                            <Input id="input-email-conta" type="email" value={userData.email} disabled/>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                                    <Grid item>
                                        <Typography variant="h6" color="primary">
                                            Hosts Permitidos
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                    {userData.hosts_acesso === undefined || userData.hosts_acesso.length === 0 ?
                                        <Typography variant="subtitle1" color="primary">
                                            Nenhum acesso encontrado.
                                        </Typography>
                                    : userData.hosts_acesso.map((host_id, idx) => {
                                        return (
                                            <Grid item key={host_id + idx}>
                                                <Chip
                                                    label={host_id}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                                    <Grid item>
                                        <Typography variant="h6" color="primary">
                                            RPAs Permitidos
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                    {userData.rpas_acesso === undefined || userData.rpas_acesso.length === 0 ?
                                        <Typography variant="subtitle1" color="primary">
                                            Nenhum acesso a RPA encontrado.
                                        </Typography>
                                    : userData.rpas_acesso.map((rpa_id, idx) => {
                                        return (
                                            <Grid item key={rpa_id + idx}>
                                                <Chip
                                                    label={rpa_id}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>

                                <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                                    <Grid item>
                                        <Typography variant="h6" color="primary">
                                            Alterar senha
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                    <Grid item md={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="input-senha-alter">Nova Senha</InputLabel>
                                            <Input id="input-senha-alter" type="password" value={newPassword} onChange={ (event) => setNewPassword(event.target.value) }/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="input-senha-alter2">Confirmar senha</InputLabel>
                                            <Input id="input-senha-alter2" type="password" value={confirmNewPassword} onChange={ (event) => setConfirmNewPassword(event.target.value) }/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={1}>
                                    <Button onClick={handleAlterPassword} color="primary">
                                        Alterar
                                    </Button>
                                    </Grid>
                                </Grid>

                            </React.Fragment>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
		</React.Fragment>
	);
}


function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value }),
    };
}

function mapStateToProps(state) {
	return {
		selected_rpa: state.rpa.selected_rpa,
        user: state.auth.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MinhaConta);
