import * as firebase from 'firebase/app'
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

// ambiente do firebase
var ambiente = "PRD";

// ========================================================================
// ==================== cuidado ao alterar ================================
// ========================================================================

var fbApp = null;
var fbAppSecondary = null;

const cred_dev = {
};

const cred_prd = {
  apiKey: "AIzaSyBth9a4dcfT6iT8zbuXXbx1GQCigMVbGVs",
  authDomain: "painel-rpa-inovacao-57493.firebaseapp.com",
  projectId: "painel-rpa-inovacao-57493",
  storageBucket: "painel-rpa-inovacao-57493.appspot.com",
  messagingSenderId: "101482087038",
  appId: "1:101482087038:web:b2e7151d9d48c7c9ff0e2f",
  measurementId: "G-2NKSPRDCSZ",
  databaseURL: "https://painel-rpa-inovacao-57493-default-rtdb.firebaseio.com/"
};

if(ambiente === "DEV") {
  fbApp = firebase.initializeApp(cred_dev);
  fbAppSecondary = firebase.initializeApp(cred_dev, 'secondary');
} else {
  fbApp = firebase.initializeApp(cred_prd);
  fbAppSecondary = firebase.initializeApp(cred_prd, 'secondary');
}

export const Auth = getAuth(fbApp);
export const AuthSecondary = getAuth(fbAppSecondary);
export const Firestore = getFirestore(fbApp);
export const Firebase = getDatabase(fbApp);