import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { set_rpa_data, add_log } from '../firebase/firestore';
import DeleteIcon from '@material-ui/icons/Delete';
import { create_rpa_action } from '../firebase/realtime';
import { convertHexToRGBA } from '../static/Utils';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 0,
        padding: 0,
        height: '400px',
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        flexDirection: 'column',
    },
    title: {
        magin: 0,
        padding: 0
    },
    item: {
        margin: 0,
        padding: 0
    },
    bgException: {
        backgroundColor: 'rgba(255, 0, 0, 0.6)'
    }
}));

export default function LogsRpa(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        console.log("component updated (logs rpa)");
    }, [props]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleCreateRpaAction = async (action) => {
        await create_rpa_action(props.rpaInfos.doc_id, action, props.rpaInfos.parameters);
        await add_log(props.user_data, "RPA: " + props.rpaInfos.identity + " (" + props.rpaInfos.doc_id + ") - ACTION: " + action)
    };

    const handleResetLogs = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('limpar_logs'))
            return

		if(props.rpaInfos.logs !== undefined && selectedDate !== null && props.rpaInfos.logs[selectedDate.toLocaleDateString('pt-BR')] !== undefined) {
            let tmp_logs = Object.assign({}, props.rpaInfos.logs);
            tmp_logs[selectedDate.toLocaleDateString('pt-BR')] = [];
            await set_rpa_data(props.rpaInfos.doc_id, 'logs', tmp_logs);
            await handleCreateRpaAction("update_logs")
        }
    }

    const handleResetOldLogs = async () => {
        if(props.user_data.permissions === undefined || !props.user_data.permissions.includes('limpar_logs'))
            return

        await handleCreateRpaAction("clear_old_logs")
    }
    
    let elements_logs = [];
    if(props.rpaInfos.logs !== undefined && selectedDate !== null && props.rpaInfos.logs[selectedDate.toLocaleDateString('pt-BR')] !== undefined) {
        for(let log of props.rpaInfos.logs[selectedDate.toLocaleDateString('pt-BR')].reverse()) {
            let log_text = "";
            let log_color = "";

            if(log.log !== undefined) {
                log_text = log.time + " - " + log.log;
                if(log.log_color !== undefined)
                    log_color = convertHexToRGBA(log.log_color, 60);

                if(log.log_type !== undefined && log.log_type === "debug") {
                    if(props.user_data.permissions !== undefined && !props.user_data.permissions.includes('debug_logs')) {
                        continue;
                    }
                }
            } 
            else
            {
                log_text = log;
            }
            
            elements_logs.push(
                <React.Fragment key={log_text}>
                    <ListItem alignItems="flex-start" className={classes.item} style={{ backgroundColor: log_color }}>
                        <ListItemText
                            primary={
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                >
                                    {log_text}
                                </Typography>
                            }
                        />
                    </ListItem>
                </React.Fragment>
            );
        }
    } 

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper className={classes.paper}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            Logs
                        </Typography>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'data log',
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {props.user_data.permissions !== undefined && props.user_data.permissions.includes("limpar_logs") &&
                        <React.Fragment>
                            <Grid item>
                                <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" size="small" onClick={() => handleResetLogs()}>
                                    Limpar logs atual
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<DeleteIcon />} variant="outlined" color="primary" size="small" onClick={() => handleResetOldLogs()}>
                                    Limpar logs antigos
                                </Button>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
                <Divider light />
                <Container>
                    <List>
                        {elements_logs.length > 0 ?
                                elements_logs
                            :
                            <Grid container justifyContent="center" alignItems="center">
                                <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                    Nenhum log disponível na data informada
                                </Typography>
                            </Grid>
                        }
                    </List>
                </Container>
            </Paper>
        </React.Fragment>
    );
}