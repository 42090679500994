import { Auth, AuthSecondary } from "../firebase/firebase";
import { get_user_data_by_user } from "../firebase/firestore";
import { updatePassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from "firebase/auth";

export async function alter_password(new_password) {
    let result = false;
    await updatePassword(Auth, new_password).then(function() {
        result = true;
    }).catch((error) => {
        console.log(error);
    });
    
    return result;
}

export async function reset_password_by_email(email) {
    let result = false;
    await sendPasswordResetEmail(Auth, email).then(() => {
        result = true;
    }).catch((error) => {
        console.log(error);
    });
    
    return result;
}

export async function create_user_by_email(email, password) {
    let result = false;
    await createUserWithEmailAndPassword(AuthSecondary, email, password).then(async (user) => {
        await get_user_data_by_user(user).then(() => { result = true; });
      }).catch((error) => {
        console.log(error);
        result = false;
    });

    return result;
}