import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid, Tabs, Tab} from '@material-ui/core';
import AnalyticsTempo from "./AnalyticsTempo";
import AnalyticsUso from "./AnalyticsUso";
import AnalyticsHoras from "./AnalyticsHoras";
import CssBaseline from '@material-ui/core/CssBaseline';

function Analytics(props) {
    const [tabSelected, setTabSelected] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            props.setRpa("page_title", "Analytics");
        };

        fetchData();
        console.log("component updated (analytics)");
    }, [props]);

    const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid container alignContent="center" alignItems="center" justifyContent="center">
                <Tabs value={tabSelected} onChange={handleChangeTab}>
                    <Tab label="TEMPO / PROCESSOS" />
                    <Tab label="UTILIZAÇÃO"/>
                    <Tab label="TEMPO / GRUPOS"/>
                </Tabs>
                <Grid container style={{ marginTop: '4vmin' }}>
                    { tabSelected === 0 &&
                        <AnalyticsTempo />
                    }

                    { tabSelected === 1 &&
                        <AnalyticsUso />
                    }
                    
                    { tabSelected === 2 &&
                        <AnalyticsHoras />
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(null, mapDispatchToProps)(Analytics);
