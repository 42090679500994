import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Bar } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { isWithinInterval, parse, subDays } from 'date-fns'
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 0,
        padding: 0,
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
    },
    containerChart: {
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '95%',
        width: '100%'
    },
    chart: {
        height: '100%',
        width: '100%'
    },
    title: {
        magin: 0,
        padding: 0
    },
}));

export default function ChartRpa(props) {
    const classes = useStyles();
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date());
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());

    const handleDateChange = (type, date) => {
        if(type === 'initial')
            setSelectedInitialDate(date);
        else
            setSelectedFinalDate(date);
    };

    let arrayLabels = [];
    let arrayTypeProcs = [];
    if(props.rpaInfos.processamentos !== undefined) {
        Object.keys(props.rpaInfos.processamentos).forEach((key_proc) => {
            let key_data = parse(key_proc, 'dd/MM/yyyy', new Date());
            try 
            {
                if(isWithinInterval(key_data, { start: subDays(selectedInitialDate, 1), end: selectedFinalDate })) {
                    arrayLabels.push(key_proc);
                    Object.keys(props.rpaInfos.processamentos[key_proc]).forEach((key_value) => {
                        if(key_value === "exceptions")
                            if(props.user_data.permissions !== undefined && !props.user_data.permissions.includes('debug_logs'))
                                return;

                        if(!arrayTypeProcs.includes(key_value))
                            arrayTypeProcs.push(key_value);
                    });
                }
            }
            catch 
            {
                return;
            }
        });
    }

    arrayLabels.sort();
    arrayTypeProcs.sort();
    let arrayData = [];
    arrayTypeProcs.forEach((key_value) => {
        let values = [];
        arrayLabels.forEach((label) => {
            values.push(props.rpaInfos.processamentos[label][key_value])
        });

        let backgroundColor = 'rgba(89, 145, 255,0.5)',
        borderColor = 'rgba(89, 145, 255, 1)',
        hoverBackgroundColor = 'rgba(89, 145, 255,0.8)',
        hoverBorderColor = 'rgba(89, 145, 255, 1)';

        if(key_value === "processados") {
            backgroundColor = 'rgba(40, 208, 31,0.5)';
            borderColor = 'rgba(22, 119, 17, 0.5)';
            hoverBackgroundColor = 'rgba(40, 208, 31, 0.8)';
            hoverBorderColor = 'rgba(22, 119, 17, 0.8)';
        } else if(key_value === "não processados") {
            backgroundColor = 'rgba(255, 0, 0,0.5)';
            borderColor = 'rgba(176, 0, 0, 0.5)';
            hoverBackgroundColor = 'rgba(255, 0, 0, 0.8)';
            hoverBorderColor = 'rgba(176, 0, 0, 0.8)';
        } else if(key_value === "exceptions") {
            backgroundColor = 'rgba(255, 200, 0,0.5)';
            borderColor = 'rgba(217, 174, 17, 0.5)';
            hoverBackgroundColor = 'rgba(255, 200, 0, 0.8)';
            hoverBorderColor = 'rgba(217, 174, 17, 0.8)';
        }

        arrayData.push({
            label: key_value,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1,
            hoverBackgroundColor: hoverBackgroundColor,
            hoverBorderColor: hoverBorderColor,
            data: values
        });
    });

    const data = {
        labels: arrayLabels,
        datasets: arrayData
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper className={classes.paper}>
                <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            Gráfico
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                value={selectedInitialDate}
                                onChange={(date) => handleDateChange('initial', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'data log',
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="inherit" className={classes.title}>
                            até
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                value={selectedFinalDate}
                                onChange={(date) => handleDateChange('final', date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'data log',
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Divider light />
                {arrayData.length > 0 ?
                    <Grid container className={classes.containerChart}>
                        <Grid item className={classes.chart}>
                            <Bar
                                data={data}
                                options={{ 
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                suggestedMin: 0
                                            }
                                        }]
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography variant="subtitle1" color="inherit" className={classes.title}>
                            Nenhum dado disponível na data informada
                        </Typography>
                    </Grid>
                }
            </Paper>
        </React.Fragment>
    );
}