import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { set_user_data } from '../../firebase/firestore';
import { reset_password_by_email } from '../../firebase/auth';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { acessos_menus } from '../../static/Menus';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%'
	},
	cardContent: {
		flexGrow: 1,
	}
}));


function AdmUser(props) {
	const snackbar = useSnackbar()
    const classes = useStyles();
    const [userPermissionList, setUserPermissionList] = useState([]);

	useEffect(() => {
        if(props.selectedUser.permissions !== undefined) {
            props.selectedUser.permissions.sort();
            setUserPermissionList(props.selectedUser.permissions);
        } else {
            setUserPermissionList([]);
        }

		console.log('component updated (adm user)')
    }, [props]);
    
    function checkPermission(name) {
        if(userPermissionList.includes(name)) {
            return true;
        }
        else
        {
            return false;
        }
    }

    const handleChangePermissionUser = (name, checked) => {
        let tmp_user_permissions = Object.assign([], props.selectedUser.permissions);
        if(checked) {
            if (!tmp_user_permissions.includes(name)) {
                tmp_user_permissions.push(name);
            }
        } 
        else 
        {
            if (tmp_user_permissions.includes(name)) {
                let idx = tmp_user_permissions.indexOf(name);
                tmp_user_permissions.splice(idx, 1);
            }
        }

        props.selectedUser.permissions = tmp_user_permissions;
        set_user_data(props.selectedUser.doc_id, 'permissions', tmp_user_permissions);
        setUserPermissionList(tmp_user_permissions);
    }

	// const handleChangeInfoUser = (key, value) => {
	// 	if (props.selectedUser !== undefined) {
	// 		let tmp_user = Object.assign({}, props.selectedUser);
	// 		tmp_user[key] = value;
	// 		set_user_data(props.selectedUser.doc_id, key, tmp_user[key]);
	// 	}
	// };

	const handleResetPassword = async () => {
		if (props.selectedUser !== undefined) {
			await reset_password_by_email(props.selectedUser.email).then((result) => {
				if(result === true) {
					snackbar.showMessage(
						'E-mail de recuperação enviado!'
					)
				}
			})
		}
	}

	return (
		<React.Fragment>
			<CssBaseline />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <React.Fragment>
                        <Grid container alignContent="center" alignItems="center" justifyContent="center">
                            <Grid item>
                                <Typography variant="h6" color="primary">
                                    Informações do Usuário
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={3} style={{ marginTop: '1vmin' }}>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="input-email-conta">Email</InputLabel>
                                    <Input id="input-email-conta" type="email" value={props.selectedUser.email} disabled/>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin' }}>
                            <Grid item>
                                <Typography variant="subtitle1" color="primary">
                                    Permissões de Acesso
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" alignItems="center" alignContent="center" style={{ marginTop: '1vmin' }}>
                            <Grid item md={6}>
                                <FormGroup row>
                                        {acessos_menus.map((item) => {
                                            return (
                                                <FormControlLabel key={item.title}
                                                    control={<Checkbox checked={checkPermission(item.value)} onChange={(event) => handleChangePermissionUser(item.value, event.target.checked)} name={item.title} />}
                                                    label={item.title}
                                                />
                                                );
                                            })
                                        }
                                </FormGroup>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="center" alignItems="center" alignContent="center" style={{ marginTop: '4vmin' }}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={() => handleResetPassword()}>
                                    Resetar Senha
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </CardContent>
            </Card>
		</React.Fragment>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
	};
}

export default connect(mapDispatchToProps)(AdmUser);
