import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { set_user_data, get_host_names_list } from '../../firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		padding: theme.spacing(1)
	},
	cardContent: {
		flexGrow: 1,
	},
}));


function AdmUserHosts(props) {
	const classes = useStyles();
    const [userHostList, setUserHostList] = useState([]);
    const [hostList, setHostList] = useState([]);
    const [loading, setLoading] = useState(true);

	useEffect(() => {
        const fetchData = async () => {
            if(hostList.length <= 0) {
                await get_host_names_list().then((hosts) => {
                    setHostList(hosts);
                    setLoading(false);
                });
            }
        };
        fetchData();

        if(props.selectedUser.hosts_acesso !== undefined) {
            props.selectedUser.hosts_acesso.sort();
            setUserHostList(props.selectedUser.hosts_acesso);
        } else {
            setUserHostList([]);
        }

		console.log('component updated (adm user hosts)')
	}, [props, hostList]);

	const handleAddFromHostList = (host_id) => {
        let tmp_host_list = Object.assign([], userHostList);
        if (!tmp_host_list.includes(host_id)) {
            tmp_host_list.push(host_id);
            
            props.selectedUser.hosts_acesso = tmp_host_list;
            set_user_data(props.selectedUser.doc_id, 'hosts_acesso', tmp_host_list);
            setUserHostList(tmp_host_list);
        }
	};

	const handleRemoveFromHostUsers = (host_id) => {
        let tmp_host_list = Object.assign([], userHostList);
        if (tmp_host_list.includes(host_id)) {
            let idx = tmp_host_list.indexOf(host_id);
            tmp_host_list.splice(idx, 1);

            props.selectedUser.hosts_acesso = tmp_host_list;
            set_user_data(props.selectedUser.doc_id, 'hosts_acesso', tmp_host_list);
            setUserHostList(tmp_host_list);
        }
    };
    
    if (loading === true) {
		return (
			<Grid container alignContent="center" alignItems="center" justifyContent="center">
				<Grid item>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}

	return (
		<React.Fragment>
			<CssBaseline />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                Hosts Disponíveis
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {hostList.length === 0 &&
                            <Typography variant="subtitle1" color="primary">
                                Nenhum host encontrado.
                            </Typography>
                        }
                        {hostList.map((host_id) => {
                            return (
                                <Grid item key={host_id}>
                                    <Chip
                                        label={host_id}
                                        deleteIcon={<AddCircleIcon />}
                                        onDelete={() => handleAddFromHostList(host_id)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ margin: '1vmin', marginTop: '4vmin' }}>
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                Hosts Permitidos
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                        {userHostList === undefined || userHostList.length === 0 ?
                            <Typography variant="subtitle1" color="primary">
                                Nenhum acesso encontrado.
                            </Typography>
                        : userHostList.map((host_id, idx) => {
                            return (
                                <Grid item key={host_id + idx}>
                                    <Chip
                                        label={host_id}
                                        onDelete={() => handleRemoveFromHostUsers(host_id)}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </CardContent>
            </Card>
		</React.Fragment>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
	};
}

export default connect(mapDispatchToProps)(AdmUserHosts);
