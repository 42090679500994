import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { create_user_by_email } from '../../firebase/auth';
import { useSnackbar } from 'material-ui-snackbar-provider';

export default function DialogRegisterUser(props) {
    const snackbar = useSnackbar()
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const handleClose = () => {
        props.handleClose(false);
    };

    const handleRegisterUser = async () => {
        if(userEmail.length > 0 && userPassword.length > 0) {
            await create_user_by_email(userEmail, userPassword).then((result) => {
                if(result === true) {
                    snackbar.showMessage(
                        'Usuário cadastrado!'
                    )
                } else {
                    snackbar.showMessage(
                        'Falha ao cadastrar usuário.'
                    )
                }
            });
        }   
    }

    return (
        <Dialog aria-labelledby="dialog ignore date schedule" open={props.open}>
            <DialogTitle>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    Cadastrar Novo Usuário
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="input-email-new">Email</InputLabel>
                            <Input id="input-email-new" type="email" value={userEmail} onChange={ (event) => setUserEmail(event.target.value) }/>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="input-senha-new">Senha</InputLabel>
                            <Input id="input-senha-new" type="password" value={userPassword} onChange={ (event) => setUserPassword(event.target.value) }/>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={handleRegisterUser} color="primary">
                    Cadastrar
                </Button>
            </DialogActions>
        </Dialog>
    );

}