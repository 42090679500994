import Login from "../views/Login";
import Administracao from "../views/Administracao";
import Analytics from "../views/Analytics";
import MinhaConta from "../views/MinhaConta";
import Rpas from "../views/Rpas";

export const staticMenu = [
    {
        path: '/login',
        component: Login,
        exact: true
    },
    {
        path: '/administracao',
        component: Administracao,
        exact: false
    },
    {
        path: '/analytics',
        component: Analytics,
        exact: false
    },
    {
        path: '/conta',
        component: MinhaConta,
        exact: false
    },
    {
        path: '/',
        component: Rpas,
        exact: true
    }
];

export const acessos_menus = [
    {
        value: 'pagina_adm',
        title: 'Pagina de Administração'
    },
    {
        value: 'pagina_analytics',
        title: 'Pagina de Analytics'
    },
    {
        value: 'menu_agendamento',
        title: 'Menu de Agendamento'
    },
    {
        value: 'limpar_logs',
        title: 'Limpar Logs'
    },
    {
        value: 'recarregar_infos',
        title: 'Recarregar Parametros'
    },
    {
        value: 'add_responsavel',
        title: 'Adicionar/Remover Responsavel'
    },
    {
        value: 'iniciar_rpa',
        title: 'Iniciar/Parar RPA'
    },
    {
        value: 'debug_logs',
        title: 'Visualizar Debug Logs'
    },
    {
        value: 'reiniciar_host',
        title: 'Reiniciar Host'
    },
    {
        value: 'desabilitar_rpas',
        title: 'Desabilitar RPAs'
    },
];