export function MapTotal(map) {
    let total = 0;

    try {
        Object.keys(map).forEach((key) => {
            Object.keys(map[key]).forEach((keyTotal) => {
                let valor = map[key][keyTotal];
                if (valor)
                    total += valor;
            })
        })
    }
    catch
    {
        total = 0;
    }

    return total;
}

//Função para orgazinar array de objetos dado um atributo
//https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
//console.log(sortByAttribute(games, 'name'));
//console.log(sortByAttribute(games, '-name'));
export function sortByAttribute(array, ...attrs) {
    // generate an array of predicate-objects contains
    // property getter, and descending indicator
    let predicates = attrs.map(pred => {
        let descending = pred.charAt(0) === '-' ? -1 : 1;
        pred = pred.replace(/^-/, '');
        return {
            getter: o => o[pred],
            descend: descending
        };
    });
    // schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
    return array.map(item => {
        return {
            src: item,
            compareValues: predicates.map(predicate => predicate.getter(item))
        };
    })
        .sort((o1, o2) => {
            let i = -1, result = 0;
            while (++i < predicates.length) {
                if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
                if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
                if (result *= predicates[i].descend) break;
            }
            return result;
        })
        .map(item => item.src);
}

export function random_rgba(transparency = 1) {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = num >> 8 & 255;
    var b = num & 255;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', '+ transparency +')';
}

export function convertHexToRGBA(hexCode, opacity) {
    let hex = hexCode.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
}

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hora, " : " horas, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto, " : " minutos, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " segundo" : " segundos") : "";
    return hDisplay + mDisplay + sDisplay; 
}

export function secondsToTime(secs)
{
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var str = `${hours}h, ${minutes}m, ${seconds}s`

    return str;
}

export function insertDadoIntoArrayAndCheckIfExists(array, val)
{
    let achou = false;
    for (let dado of array) {
        if (val.data.getTime() === dado.data.getTime()) {
            dado.processados += val.processados
            achou = true
            break
        }
    }

    if (achou === false) {
        array.push(val)
    }

    return array;
}

export function RangeOfDatesToArray(start, end) {
    let result = [], current = new Date(start);
    while (current <= end)
        result.push(current) && (current = new Date(current)) && current.setDate(current.getDate() + 1);
    return result;
}