import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { add_new_grupo } from '../../firebase/firestore';
import { useSnackbar } from 'material-ui-snackbar-provider';

export default function DialogAddGrupo(props) {
    const snackbar = useSnackbar()
    const [grupoNome, setGrupoNome] = useState("");

    const handleClose = () => {
        props.handleClose(false);
    };

    const handleAddGrupo = async () => {
        if(grupoNome.length > 0) {
            await add_new_grupo(grupoNome).then((result) => {
                if(result === true) {
                    snackbar.showMessage(
                        'Grupo cadastrado!'
                    )
                } else {
                    snackbar.showMessage(
                        'Falha ao cadastrar grupo.'
                    )
                }
            });
        }   
    }

    return (
        <Dialog aria-labelledby="dialog ignore date schedule" open={props.open}>
            <DialogTitle>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    Cadastrar Novo Grupo
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <InputLabel id="input-grupo">Nome do grupo</InputLabel>
                            <Input id="input-grupo" type="text" value={grupoNome} onChange={ (event) => setGrupoNome(event.target.value) }/>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={handleAddGrupo} color="primary">
                    Cadastrar
                </Button>
            </DialogActions>
        </Dialog>
    );

}