import React, { useEffect, useState } from 'react';
import { get_user_logs } from '../../firebase/firestore';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import DateFnsUtils from '@date-io/date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
	card: {
        height: '100%',
        width: '100%',
        padding: '1vmin'
	},
	cardContent: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        height: '400px',
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        flexDirection: 'column',
        width: '100%'
    },
    title: {
        magin: 0,
        padding: 0
    },
    item: {
        margin: 0,
        padding: 0,
        width: '100%'
    },
}));


function AdmUserLogs(props) {
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [logsList, setLogsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
			await get_user_logs(selectedDate, props.selectedUser.email).then((users) => {
                setLogsList(users);
                setLoading(false);
			});
		};

		fetchData();
        console.log("component updated (adm user logs)");
    }, [props, selectedDate]);

    const handleDateChange = (date) => {
        setLoading(true);
        setSelectedDate(date);
    };

	return (
		<React.Fragment>
			<CssBaseline />
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="center" alignItems="center" alignContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h6" color="inherit" className={classes.title}>
                                Logs do Usuário
                            </Typography>
                        </Grid>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'data log',
                                    }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Divider light />
                    { loading ?
                        <Grid container alignContent="center" alignItems="center" justifyContent="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    :
                        <Grid container spacing={2} justifyContent="center" alignItems="center" alignContent="center">
                            <Grid item>
                                <List>
                                    {logsList !== undefined && logsList.length > 0 ?
                                        logsList.reverse().map((log, idx) => {
                                            return (
                                                <React.Fragment key={log.action + idx}>
                                                    <ListItem alignItems="flex-start" className={classes.item}>
                                                        <ListItemText
                                                            primary={
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="textPrimary"
                                                                >
                                                                    {log.date.toLocaleTimeString('pt-BR') + " - " + log.action}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Typography variant="subtitle1" color="inherit" className={classes.title}>
                                                Nenhum log disponível na data informada
                                            </Typography>
                                        </Grid>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    }
                </CardContent>
            </Card>
		</React.Fragment>
	);
}

export default AdmUserLogs;
