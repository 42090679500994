const initialState = {
  selected_rpa: undefined,
  selected_host: undefined,
  page_title: undefined,
  user_data: undefined,
  alertSnack: undefined 
};

function rpa(state = initialState, action) {
  if (action.type === "SET_RPA") {
    return Object.assign({}, state, {
      [action.key]: action.value
    });
  }
  if (action.type === "RESET_RPA") {
    return Object.assign({}, state, initialState);
  }
  return state;
}

export default rpa;