import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_grupos_tempo_processamentos } from "../firebase/firestore";
import DateFnsUtils from '@date-io/date-fns';
import { isAfter } from 'date-fns'
import { secondsToTime } from "../static/Utils";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, withStyles, Paper, CircularProgress, CssBaseline, Typography, Grid, CardContent, Card, Button, TableRow, TableHead, TableCell, TableContainer, TableBody, Table } from '@material-ui/core';

const useStyles = makeStyles({
    cardTable: {
        maxHeight: 800,
        witdh: '100%',
        alignItems: 'center',
        overflowY: 'scroll'
    },

    tableRows: {
        textAlign: 'center'
    },

    tableCells: {
        textAlign: 'center',
        border: '1px solid white',
        color: 'black',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function AnalyticsHoras(props) {
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date(Date.now() - (3600 * 1000 * 24)));
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [analyticsTotalData, setAnalyticsTotalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        console.log("component updated (analyticsHoras)");
    }, [analyticsTotalData]);

    const handleDateChange = (type, date) => {
        if (type === 'initial') {
            if (isAfter(date, selectedFinalDate) === false)
                setSelectedInitialDate(date);
        } else {
            setSelectedFinalDate(date);
        }

        setAnalyticsTotalData(undefined);
    }

    const handleClickOk = async () => {
        setLoading(true);
        await get_grupos_tempo_processamentos(selectedInitialDate, selectedFinalDate).then((result) => {
            setAnalyticsTotalData(result);
        }).finally(() => {
            setLoading(false);
        });
    }

    if (loading === true) {
        return (
            <Grid container alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    let elements_data_head = [];
    let rows_processos = [];
    if (analyticsTotalData !== undefined) 
    {
        console.log(analyticsTotalData)

        let totalPocessosColumnMonth = {};
        let totalProcessosAllGrupos = 0;
        let totalQtdRpas = 0;

        for(let grupo of analyticsTotalData) 
        {
            let monthTempoKeys = Object.keys(grupo.tempos_processos);
            if (elements_data_head.length === 0) {
                for(let month of monthTempoKeys) {
                    elements_data_head.push(<StyledTableCell key={month} align="center">{month}</StyledTableCell>)
                }
                elements_data_head.push(<StyledTableCell key={"totalgrupo"} align="center" style={{ backgroundColor: 'lightgray', border: 'None' }}>TOTAL</StyledTableCell>)
            }

            for(let month of monthTempoKeys) {
                if (totalPocessosColumnMonth[month] === undefined)
                    totalPocessosColumnMonth[month] = 0;
                totalPocessosColumnMonth[month] += grupo.tempos_processos[month];
                
                if (grupo.totalProcessosRowMonths === undefined)
                    grupo.totalProcessosRowMonths = 0;
                grupo.totalProcessosRowMonths += grupo.tempos_processos[month];
            }

            totalProcessosAllGrupos += grupo.totalProcessosRowMonths;
            totalQtdRpas += grupo.qtd_rpas;
        }



        for(let grupo of analyticsTotalData) 
        {
            let cells_processos = [];
            let processTempoKeys = Object.keys(grupo.tempos_processos);
            for(let month of processTempoKeys) {
                let val = grupo.tempos_processos[month]
                cells_processos.push(
                    <StyledTableCell key={grupo.nome+month} align="center">{secondsToTime(val)}</StyledTableCell>
                );
            }

            rows_processos.push(
                <StyledTableRow key={grupo.nome+"infos"} className={classes.tableRows}>
                    <StyledTableCell align="center">{grupo.nome}</StyledTableCell>
                    <StyledTableCell align="center">{grupo.qtd_rpas}</StyledTableCell>
                    {cells_processos}
                    <StyledTableCell align="center" style={{ fontWeight: 'bold', backgroundColor: 'lightgray', border: 'None' }}>{secondsToTime(grupo.totalProcessosRowMonths)}</StyledTableCell>
                </StyledTableRow>
            );
        }
        
        let cells_total_column = [];
        let processTempoKeys = Object.keys(totalPocessosColumnMonth);
        for(let month of processTempoKeys) {
            cells_total_column.push(
                <StyledTableCell key={month+"total"} align="center" style={{ fontWeight: 'bold' }}>{secondsToTime(totalPocessosColumnMonth[month])}</StyledTableCell>
            );
        }

        rows_processos.push(
            <StyledTableRow key={"totalprocessos"} className={classes.tableRows} style={{ backgroundColor: 'lightgray' }}>
                <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>TOTAL</StyledTableCell>
                <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>{totalQtdRpas}</StyledTableCell>
                {cells_total_column}
                <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>{secondsToTime(totalProcessosAllGrupos)}</StyledTableCell>
            </StyledTableRow>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />

            <Grid container alignContent="center" alignItems="center" justifyContent="center">
                <Grid item md={6} xs={6} sm={6} lg={6}>
                    <Card raised={false}>
                        <CardContent>
                            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography variant="h5" color="primary" align="center">
                                        Período
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        De:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/yyyy"
                                            views={['year', 'month']}
                                            value={selectedInitialDate}
                                            onChange={(date) => handleDateChange('initial', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        até
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/yyyy"
                                            views={['year', 'month']}
                                            value={selectedFinalDate}
                                            onChange={(date) => handleDateChange('final', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                            <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin' }}>
                                <Grid item md={6} xs={6} sm={6} lg={6} style={{ textAlign: 'center' }}>
                                    <Button onClick={() => handleClickOk()} color="primary" variant="contained">
                                        OK
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            
            <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin' }}>
                <Grid item md={10} xs={10} sm={10} lg={10} style={{ textAlign: 'center' }}>
                    { analyticsTotalData && elements_data_head && rows_processos &&
                    <TableContainer component={Paper} className={classes.cardTable}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">GRUPO</StyledTableCell>
                                    <StyledTableCell align="center">RPA's</StyledTableCell>
                                    {elements_data_head}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows_processos}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(null, mapDispatchToProps)(AnalyticsHoras);
