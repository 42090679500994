import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { reset_password_by_email } from '../../firebase/auth';
import { useSnackbar } from 'material-ui-snackbar-provider';

export default function DialogForgotPassword(props) {
    const snackbar = useSnackbar()
    const [userEmail, setUserEmail] = useState("");

    const handleClose = () => {
        props.handleClose(false);
    };

    const handleResetPassword = async () => {
		if (userEmail !== undefined && userEmail.length > 5) {
			await reset_password_by_email(userEmail).then((result) => {
                snackbar.showMessage(
                    'E-mail de recuperação enviado!'
                )
            })
            handleClose();
        }
    }

    return (
        <Dialog aria-labelledby="dialog forgot password" open={props.open}>
            <DialogTitle>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    Digite seu email:
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <InputLabel>Email</InputLabel>
                            <Input type="email" value={userEmail} onChange={ (event) => setUserEmail(event.target.value) }/>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button onClick={handleResetPassword} color="primary">
                    Recuperar
                </Button>
            </DialogActions>
        </Dialog>
    );

}