import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Routing from './routes/Routing';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';
serviceWorker.unregister();

const { store, persistor } = configureStore();
const theme = createTheme({
    palette: {
        primary: {
            main: '#008b81',
            light: '#00c9bb',
            dark: '#948d87',
            contrastText: '#fff',
        },
        secondary: {
            main: '#5b574d',
            light: '#857e6e',
            dark: '#948d87',
            contrastText: '#fff'
        }
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                '&:hover': { // changes colors for hover state
                    backgroundColor: '#f47920',
                    color: '#000000'
                }
            }
        }
    }
});

function Root() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <PersistGate persistor={persistor}>
                    <Routing history={history} />
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

ReactDOM.render(<Root />, document.getElementById("root"));
