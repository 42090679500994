import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from "redux-thunk";
import { verifyAuth } from "./actions/";
import auth from "./reducers/auth";
import rpa from "./reducers/rpa";
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const persistConfig = {
  key: 'rpa',
  storage: storage
}

export const persistAuthConfig = {
  key: 'auth',
  storage: storage
} 

export const history = createBrowserHistory()
export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      auth: persistReducer(persistAuthConfig, auth),
      rpa: persistReducer(persistConfig, rpa),
    }),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware
      ),
    ),
  )

  store.dispatch(verifyAuth());
  let persistor = persistStore(store);
  return { store, persistor }
}