import { Firestore } from './firebase';
import { Timestamp, arrayUnion, collection, getDoc, doc, getDocs, query, where, setDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { isWithinInterval, parse, sub, format, lastDayOfMonth } from 'date-fns';
import { insertDadoIntoArrayAndCheckIfExists, RangeOfDatesToArray } from '../static/Utils';

export async function get_rpa_names_list() {
    let rpas = [];
    const docRef = collection(Firestore, 'rpas');
    const docSnaps = await getDocs(docRef);

    docSnaps.forEach(doc => {
        let data = doc.data();
        if(data.identity !== undefined && data.identity.length > 1 && !rpas.includes(data.identity)) {
            rpas.push(data.identity);
        }
    });

    return rpas;
}

export async function get_all_nomes_rpas_grupos() {
    let nomes = [];
    await get_rpa_names_list().then(async all_nomes_rpas => {
        let nomes_nos_grupos = [];
        const docRef = collection(Firestore, 'grupos');
        const docSnaps = await getDocs(docRef);

        docSnaps.forEach(doc => {
            let data = doc.data();
            nomes_nos_grupos += data.rpas;
        });

        for(let nome_rpa of all_nomes_rpas) {
            if (nomes_nos_grupos.includes(nome_rpa) === false)
                nomes.push(nome_rpa)
        }
    });

    return nomes
}

export async function get_grupos() {
    let grupos = [];
    const docRef = collection(Firestore, 'grupos');
    const docSnaps = await getDocs(docRef);

    docSnaps.forEach(doc => {
        let grupo = {
            ...doc.data(),
            doc_id: doc.id
        }

        grupos.push(grupo);
    });

    return grupos
}

export async function add_new_grupo(nome_grupo) {
    const docRef = doc(collection(Firestore, "grupos"));

    await setDoc(docRef, {
            nome: nome_grupo,
            rpas: []
        }, { merge: true }
    );
    return true;
}

export async function add_rpa_grupo(doc_id, rpa_name) {
    const docRef = doc(Firestore, 'grupos', doc_id);

    await updateDoc(docRef, {
            rpas: arrayUnion(rpa_name)
        },
    );
    return true;
}

export function stream_grupos_list(observer) {
    return onSnapshot(collection(Firestore, "grupos"), observer)
}

export async function set_grupo_data(id_grupo, key, value) {
    const docRef = doc(Firestore, 'grupos', id_grupo);
    
    await setDoc(docRef, {
            [key]: value
        }, { merge: true }
        );
}

export async function get_rpa_list_by_user_and_host(user, host_id) {
    let rpa_list = [];
    if(user !== undefined && host_id !== undefined) {
        await get_user_data_by_user(user).then(async (user_data) => {
            if(user_data.hosts_acesso === undefined || user_data.rpas_acesso === undefined)
                return [];

            await get_host_by_id(host_id).then(async (host_data) => {
                for(let host_rpa of host_data.rpas) {
                    if (user_data.rpas_acesso.includes(host_rpa.rpa_identity) && user_data.hosts_acesso.includes(host_id)) {
                        let rpa_data = await get_rpa_by_id(host_rpa.doc_id);
                        if(rpa_data !== undefined)
                            rpa_list.push(rpa_data);
                    }
                };
            });
        });
    }
    return rpa_list
}

export async function get_host_by_id(host_id) {
    let host_data = undefined;
    const docRef = doc(Firestore, "hosts", host_id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let data = docSnap.data();
        host_data = {
            ...data,
            doc_id: docSnap.id
        }
    }

    return host_data
}

export async function get_host_names_list() {
    let hosts = [];
    const docRef = collection(Firestore, 'hosts')
    const docSnaps = await getDocs(docRef);

    docSnaps.forEach(doc => {
        let data = doc.data();
        let host_state = {
            ...data,
            doc_id: doc.id
        }
        if(!hosts.includes(host_state.doc_id))
            hosts.push(host_state.doc_id)
    });
    return hosts
}

export async function get_host_list() {
    let hosts = [];
    const docRef = collection(Firestore, 'hosts')
    const docSnaps = await getDocs(docRef);

    docSnaps.forEach(doc => {
        let data = doc.data();
        let host_state = {
            ...data,
            doc_id: doc.id
        }
        if (host_state.doc_id !== undefined)
            hosts.push(host_state)
    });
    return hosts
}

export async function get_host_list_by_user(user) {
    let hosts = [];
    await get_user_data_by_user(user).then(async (user_data) => {
        if(user_data.hosts_acesso !== undefined) {
            for(let host_id of user_data.hosts_acesso) {
                let host_data = await get_host_by_id(host_id);
                
                if (host_data !== undefined && host_data.doc_id !== undefined)
                    hosts.push(host_data);
            }
        }
    });

    return hosts;
}

export async function get_rpa_by_id(id_rpa) {
    let rpa_data = undefined;
    const docRef = doc(Firestore, "rpas", id_rpa);
    const docSnap = await getDoc(docRef);
   
    if (docSnap.exists()) {
        let data = docSnap.data();
        rpa_data = {
            ...data,
            doc_id: docSnap.id
        }
    }

    return rpa_data;
}

export function stream_get_rpa_by_id(id_rpa, observer) {
    return onSnapshot(doc(Firestore, "rpas", id_rpa), observer);
}

export async function set_rpa_data(id_rpa, key, value) {
    const docRef = doc(Firestore, 'rpas', id_rpa);

    await setDoc(docRef, {
        [key]: value
    }, { merge: true });
}

export async function count_rpas_running_in_host(host_name) {
    let rpas_running = 0;
    const q = query(collection(Firestore, "rpas"), where("hostname", "==", host_name));
    const docSnaps = await getDocs(q);

    docSnaps.forEach(doc => {
        let data = doc.data();
        if(data.status === "Em Execução")
            rpas_running += 1
    });

    return rpas_running;
}

export async function get_users_list() {
    let users = [];
    const docRef = collection(Firestore, 'users');
    const docSnaps = await getDocs(docRef);

    docSnaps.forEach(doc => {
        let data = doc.data()
        let user_state = {
            ...data,
            doc_id: doc.id
        }
        users.push(user_state)
    });

    return users;
}

export function stream_get_users_list(observer) {
    return onSnapshot(collection(Firestore, "users"), observer);
}

export async function set_user_data(id_user, key, value) {
    const docRef = doc(Firestore, 'users', id_user);

    await setDoc(docRef, {
            [key]: value
        }, { merge: true }
        );
}

export async function get_user_data_by_user(user) {
    if(user.uid === undefined) {
        user = user.user;
    }

    let user_data = {};
    if (user.uid !== undefined) {
        const docRef = doc(Firestore, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = docSnap.data();
            user_data = {
                ...data,
                doc_id: docSnap.id
            }
        }
        else 
        {
            let data = {
                email: user.email,
                permissions: [],
                rpas_acesso: [],
                hosts_acesso: []
            };
            await setDoc(docRef, data);
            user_data = {
                ...data,
                doc_id: user.uid
            }
        }
    }

    return user_data
}

export async function add_log(user, action) {
    const date = new Date();
    const log_doc_id = "logs-" + date.getDay() + "-" + date.getMonth() + "-" + date.getFullYear();
    const docRef = doc(Firestore, 'logs', log_doc_id);

    await setDoc(docRef, {
        logs: arrayUnion({ 
            date: Timestamp.fromDate(date),
            action: action,
            whois: user.email
        })
    }, { merge: true });
}

export async function get_user_logs(date, user_email) {
    let logs = [];
    const log_doc_id = "logs-" + date.getDay() + "-" + date.getMonth() + "-" + date.getFullYear();
    const docRef = doc(Firestore, "logs", log_doc_id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        let logs_data = docSnap.data()
        for(let log of logs_data.logs) {
            log.date = log.date.toDate();
            if(log.whois === user_email)
                logs.push(log);
        }
    }

    return logs
}

export async function get_estatisticas_by_rpa_name(rpa_name) {
    let analytics_data = {};
    if(rpa_name !== undefined) {
        const docRef = doc(Firestore, "estatisticas", rpa_name);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {
            analytics_data = docSnap.data();
        }
    }

    return analytics_data;
}

export async function set_estatisticas_by_rpa_name(rpa_name, key, value) {
    if(rpa_name !== undefined) {
        const docRef = doc(Firestore, 'estatisticas', rpa_name);

        await setDoc(docRef, {
                [key]: value
            }, { merge: true }
        );
    }
}

export async function get_analytics_by_rpa_name(rpa_name, initial_date, final_date) {
    let analytics_data = {
        processamentos: {
            total: 0,
            lidos: 0,
            dados: []
        },
        tempo_processamentos: {
            total: 0,
            lidos: 0
        }
    };

    if(rpa_name !== undefined) {
        const q = query(collection(Firestore, "rpas"), where("identity", "==", rpa_name));

        await getDocs(q).then((snapshot) => {
            snapshot.forEach(doc => {
                const rpa_data = doc.data();

                if(rpa_data.estatisticas !== undefined && rpa_data.processamentos !== undefined)
                {
                    const keys_estatisticas_datas = Object.keys(rpa_data.estatisticas);
                    for (let key_data of keys_estatisticas_datas) {
                        const data = parse(key_data, 'dd/MM/yyyy', new Date());
                        if (isWithinInterval(data, { start: sub(initial_date, {days: 1}), end: final_date }) === false)
                            continue

                        const estatistica = rpa_data.estatisticas[key_data];
                        if (estatistica.tempo_processo > 0) {
                            analytics_data.tempo_processamentos.total += estatistica.tempo_processo;
                            analytics_data.tempo_processamentos.lidos += 1;
                        }
                    }

                    const keys_processamentos_datas = Object.keys(rpa_data.processamentos);
                    for (let key_data of keys_processamentos_datas) {
                        const data = parse(key_data, 'dd/MM/yyyy', new Date());
                        if (isWithinInterval(data, { start: sub(initial_date, {days: 1}), end: final_date }) === false)
                            continue
                        
                        let dado = {
                            data: data,
                            processados: 0
                        }
                            
                        const processamento = rpa_data.processamentos[key_data];
                        if (processamento.processados !== undefined && processamento.processados > 0) {
                            analytics_data.processamentos.total += processamento.processados;
                            analytics_data.processamentos.lidos += 1;
                            dado.processados = processamento.processados;
                            //analytics_data.processamentos.dados.push(dado);
                            analytics_data.processamentos.dados = insertDadoIntoArrayAndCheckIfExists(analytics_data.processamentos.dados, dado);
                        }
                    }
                }

            });

            if (analytics_data.processamentos.lidos > 0)
                analytics_data.processamentos.media = analytics_data.processamentos.total / analytics_data.processamentos.lidos;
            else
                analytics_data.processamentos.media = 0

            if (analytics_data.tempo_processamentos.lidos > 0)
                analytics_data.tempo_processamentos.media = analytics_data.tempo_processamentos.total / analytics_data.tempo_processamentos.lidos;
            else
                analytics_data.tempo_processamentos.media = 0

        });
    }

    return analytics_data
}


export async function get_analytics_total_with_dates(initial_date, final_date, filtro_rpas=[]) {
    const docRef = collection(Firestore, 'rpas');

    return await getDocs(docRef).then(async (snapshot) => {
        let processamentos_rpas = {};

        snapshot.forEach(async (doc) => {
            const rpa_data = doc.data();

            if (rpa_data.identity !== undefined && (rpa_data.disabled === false || rpa_data.disabled === undefined)) {
                if (filtro_rpas.includes(rpa_data.identity) || filtro_rpas.length === 0)
                {
                    // verificar se rpa ja ta na lista, se não adiciona
                    if (processamentos_rpas[rpa_data.identity] === undefined)
                        processamentos_rpas[rpa_data.identity] = {}
                    
                    if(rpa_data.processamentos !== undefined)
                    {
                        const keys_processamentos_datas = Object.keys(rpa_data.processamentos);
                        for (let key_data of keys_processamentos_datas) 
                        {
                            const data = parse(key_data, 'dd/MM/yyyy', new Date());
                            const date_inicial = sub(initial_date, {days: 1});

                            if (isWithinInterval(data, { start: date_inicial, end: final_date }) === false)
                                continue

                            // verificar se data ja esta na lista do rpa
                            if (processamentos_rpas[rpa_data.identity][key_data] === undefined) {
                                processamentos_rpas[rpa_data.identity][key_data] = { processados: 0 }
                            }
                                
                            const processamento = rpa_data.processamentos[key_data];
                            if (processamento.processados !== undefined && processamento.processados > 0) {
                                processamentos_rpas[rpa_data.identity][key_data]['processados'] += processamento.processados;
                            }
                        }
                    }
                }
            }
        });

        return processamentos_rpas
    });
}

export async function get_analytics_total(initial_date, final_date, filtro_rpas=[]) {
    const docRef = collection(Firestore, 'rpas');

    let rpas_data = [];
    await getDocs(docRef).then(async (docRpaSnap) => {
        docRpaSnap.forEach((doc_rpa) => {
            let data = doc_rpa.data();
            rpas_data.push(data);
        });
    })

    let estatisticas_tempo_economizado = {
        total: 0,
        lidos: 0
    };

    for (let rpa_data of rpas_data)
    {
        if (filtro_rpas.includes(rpa_data.identity) || filtro_rpas.length === 0)
        {
            if(rpa_data.processamentos !== undefined)
            {
                let rpa_estatisticas_data = await get_estatisticas_by_rpa_name(rpa_data.identity);
                if (rpa_estatisticas_data && rpa_estatisticas_data.tempo_medio_humano) 
                {
                    const tempo_media_humano = parseInt(rpa_estatisticas_data.tempo_medio_humano);
                    if (tempo_media_humano > 0) 
                    {
                        let soma_processamentos = 0;
                        const keys_processamentos_dates = Object.keys(rpa_data.processamentos);
                        for (let data_str of keys_processamentos_dates) {
                            const date_key = parse(data_str, 'dd/MM/yyyy', new Date());
                            const date_inicial = sub(initial_date, {days: 1});

                            if (isWithinInterval(date_key, { start: date_inicial, end: final_date }))
                            {    
                                const processamento = rpa_data.processamentos[data_str];
                                if (processamento.processados !== undefined && processamento.processados > 0) {
                                    soma_processamentos += processamento.processados;
                                }
                            }
                        }

                        estatisticas_tempo_economizado.total += (soma_processamentos * tempo_media_humano)
                        estatisticas_tempo_economizado.lidos += 1
                    }
                }
            }
        }
    }

    return estatisticas_tempo_economizado;
}

export async function get_grupos_tempo_processamentos(data_inicial, data_final) {
    const docGrupoRef = collection(Firestore, 'grupos');
    let month_data_inicial = data_inicial.setDate(1);
    let month_data_final = lastDayOfMonth(data_final);
    let grupos = [];
    let grupos_data = [];

    await getDocs(docGrupoRef).then((docGrupoSnaps) => {
        docGrupoSnaps.forEach((doc_grupo) => {
            let data = doc_grupo.data();
            if (data.nome !== "Todos")
                grupos_data.push(data);
        });
    });
    
    for (let grupoData of grupos_data)
    {
        let grupo = {
            nome: grupoData.nome,
            tempos_processos: {},
            qtd_rpas: grupoData.rpas.length
        };

        let datas = RangeOfDatesToArray(data_inicial, data_final)
        for (let date of datas) 
        {
            let date_month = format(date, 'MM/yyyy');
            if (grupo.tempos_processos[date_month] === undefined)
                grupo.tempos_processos[date_month] = 0;
        }

        for (let identity of grupoData.rpas) 
        {
            let rpa_estatisticas_data = await get_estatisticas_by_rpa_name(identity);
            let queryRpas = query(collection(Firestore, "rpas"), where("identity", "==", identity));
            let docsRpa = await getDocs(queryRpas);

            if (rpa_estatisticas_data && rpa_estatisticas_data.tempo_medio_humano) 
            {
                docsRpa.forEach((docRpa) => {
                    let rpaData = docRpa.data();
                    if (rpaData.processamentos) 
                    {
                        let tempo_media_humano = parseInt(rpa_estatisticas_data.tempo_medio_humano);
                        let datas_processamentos = Object.keys(rpaData.processamentos);
                        
                        for (let data_processamento of datas_processamentos) 
                        {
                            let processamentos = rpaData.processamentos[data_processamento];
                            let date_processamento = parse(data_processamento, 'dd/MM/yyyy', new Date());
                            let date_month = format(date_processamento, 'MM/yyyy');
                            
                            if (isWithinInterval(date_processamento, { start: sub(month_data_inicial, {days: 1}), end: month_data_final }))
                            {
                                if (processamentos.processados !== undefined && processamentos.processados > 0 && tempo_media_humano > 0)
                                    grupo.tempos_processos[date_month] += (processamentos.processados * tempo_media_humano);
                            }
                        }
                    }
                });
            }
        }
        grupos.push(grupo);
    }

    return grupos;
}



