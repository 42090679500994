export const repeat_values = [
    { 
        value: "every day",
        title: "Todos os dias"
    },
    { 
        value: "one time",
        title: "Uma vez"
    },
    { 
        value: "monthly",
        title: "Mensalmente"
    },
    { 
        value: "every x minutes",
        title: "A cada x minutos"
    }
];

export const action_values = [
    { 
        value: "iniciar",
        title: "Iniciar"
    },
    { 
        value: "parar",
        title: "Parar"
    },
    { 
        value: "restart_machine",
        title: "Reiniciar Host"
    }
];

export const ignore_values = [
    { 
        value: "0",
        title: "Segunda"
    },
    { 
        value: "1",
        title: "Terça"
    },
    { 
        value: "2",
        title: "Quarta"
    },
    { 
        value: "3",
        title: "Quinta"
    },
    { 
        value: "4",
        title: "Sexta"
    },
    { 
        value: "5",
        title: "Sábado"
    },
    { 
        value: "6",
        title: "Domingo"
    },
    { 
        value: "feriados",
        title: "Feriados"
    },
    { 
        value: "pos feriados",
        title: "Pós Feriados"
    },
];