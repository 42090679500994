import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Typography, Grid } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { logoutUser } from "../actions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));


function NavBar(props) {
    let history = useHistory();
    const classes = useStyles();
    const { dispatch } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const sairAccount = () => {
        handleMenuClose();
        dispatch(logoutUser(props.user_data));
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleRedirect = (to) => {
        history.push(to)
    }

    const menuId = 'dropdown-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handleRedirect("/")}>RPA's</MenuItem>
            <MenuItem onClick={() => handleRedirect("/Conta")}>Minha conta</MenuItem>
            {props.user_data !== undefined && props.user_data.permissions !== undefined && props.user_data.permissions.includes("pagina_adm") &&
                <MenuItem onClick={() => handleRedirect("/Administracao")}>Administração</MenuItem>
            }
            {props.user_data !== undefined && props.user_data.permissions !== undefined && props.user_data.permissions.includes("pagina_analytics") &&
                <MenuItem onClick={() => handleRedirect("/Analytics")}>Analytics</MenuItem>
            }
            <MenuItem onClick={sairAccount}>Sair</MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar
                position="static"
                className={classes.appBar}>
                <Toolbar>
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography component="h2" variant="h4" color="inherit">
                            {props.page_title !== undefined ? props.page_title : ""}
                        </Typography>
                    </Grid>
                    <IconButton
                        edge="end"
                        aria-label="dropdown menu"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </React.Fragment >
    );
}

function mapStateToProps(state) {
    return {
        page_title: state.rpa.page_title,
        user_data: state.rpa.user_data,
    };
}

export default connect(mapStateToProps)(NavBar);
