import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { set_estatisticas_by_rpa_name, get_estatisticas_by_rpa_name, get_analytics_by_rpa_name, get_analytics_total, get_grupos, get_rpa_names_list } from "../firebase/firestore";
import { sortByAttribute } from "../static/Utils";
import { secondsToTime, secondsToHms } from "../static/Utils";
import DateFnsUtils from '@date-io/date-fns';
import { isAfter, format } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, InputLabel, Input, MenuItem, FormControl, Select, CircularProgress, CssBaseline, Typography, Grid, CardContent, Card, Button, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

const useStyles = makeStyles({
    cardTable: {
        maxHeight: 500,
        alignItems: 'center',
        overflowY: 'scroll'
    },
});

function AnalyticsTempo(props) {
    const [gruposList, setGruposList] = useState([]);
    const [rpaNameList, setRpaNameList] = useState([]);
    const [allRpasNames, setAllRpasNames] = useState([]);
    const [selectedRpaName, setSelectedRpaName] = useState(undefined);
    const [selectedGrupo, setSelectedGrupo] = useState(undefined);
    const [analyticsData, setAnalyticsData] = useState(undefined);
    const [analyticsTotalData, setAnalyticsTotalData] = useState(undefined);
    const [tempoDeProcessoHumano, setTempoDeProcessoHumano] = useState(undefined);
    const [selectedInitialDate, setSelectedInitialDate] = useState(new Date(Date.now() - (3600 * 1000 * 24)));
    const [selectedFinalDate, setSelectedFinalDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            
            
            if (gruposList.length === 0) {
                setLoading(true);
                await get_grupos().then((result) => {
                    setGruposList(result);
                }).then(async () => {
                    await get_rpa_names_list().then((result) => {
                        setAllRpasNames(result);
                    })
                }).finally(() => {
                    setLoading(false);
                });
            }

        };

        fetchData();
        console.log("component updated (analytics)");
    }, [analyticsTotalData, analyticsData, gruposList]);

    const handleDateChange = (type, date) => {
        if (type === 'initial') {
            if (isAfter(date, selectedFinalDate) === false)
                setSelectedInitialDate(date);
        }
        else
            setSelectedFinalDate(date);

        setAnalyticsTotalData(undefined);
        setAnalyticsData(undefined);
    };

    const handleChangeSelectedGrupo = async (index) => {
        let grupo = gruposList[index];
        setSelectedGrupo(index);
        if (grupo.nome === "Todos") {
            setRpaNameList(allRpasNames);
        } else {
            setRpaNameList(grupo.rpas);
        }
        setAnalyticsTotalData(undefined);
        setAnalyticsData(undefined);
        setSelectedRpaName(undefined);
    };

    const handleChangeSelectedRpaName = async (rpa_name) => {
        setSelectedRpaName(rpa_name);
        setAnalyticsData(undefined);
    };

    const handleChangeTempoHumano = async (tempo) => {
        await set_estatisticas_by_rpa_name(selectedRpaName, 'tempo_medio_humano', tempo);
        setTempoDeProcessoHumano(tempo);
    }

    const handleClickOk = async () => {
        if (selectedRpaName !== undefined && selectedGrupo !== undefined) {
            setLoading(true);
            await get_estatisticas_by_rpa_name(selectedRpaName).then((result) => {
                if (result !== undefined && result.tempo_medio_humano !== undefined)
                    setTempoDeProcessoHumano(result.tempo_medio_humano);
            });

            await get_analytics_by_rpa_name(selectedRpaName, selectedInitialDate, selectedFinalDate).then((result) => {
                result.processamentos.dados = sortByAttribute(result.processamentos.dados, '-data');
                setAnalyticsData(result);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleClickCalcularTotal = async () => {
        if (selectedGrupo !== undefined && gruposList[selectedGrupo].rpas !== undefined) {
            setLoading(true);
            let rpas = gruposList[selectedGrupo].rpas
            if (gruposList[selectedGrupo].nome === "Todos")
                rpas = []
            await get_analytics_total(selectedInitialDate, selectedFinalDate, rpas).then((result) => {
                console.log(result)
                setAnalyticsTotalData(result);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    if (loading === true) {
        return (
            <Grid container alignContent="center" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline />

            <Grid container alignContent="center" alignItems="center" justifyContent="center">
                <Grid item md={6} xs={6} sm={6} lg={6}>
                    <Card raised={false}>
                        <CardContent>
                            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography variant="h5" color="primary" align="center">
                                        Período
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        De:
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            value={selectedInitialDate}
                                            onChange={(date) => handleDateChange('initial', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="inherit">
                                        até
                                    </Typography>
                                </Grid>
                                <Grid item md={3} xs={4} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            value={selectedFinalDate}
                                            onChange={(date) => handleDateChange('final', date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'data log',
                                            }}
                                            InputProps={{ disableUnderline: false }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>

                            <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={2} style={{ marginTop: '4vmin' }}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography variant="h5" color="primary" align="center">
                                        Área / RPA:
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={5} sm={5}>
                                    <FormControl fullWidth={true}>
                                        <Select
                                            align="center"
                                            id="select-grupos"
                                            value={selectedGrupo === undefined ? 'selecione' : selectedGrupo}
                                            onChange={(event) => handleChangeSelectedGrupo(event.target.value)}
                                        >
                                            <MenuItem value={'selecione'} disabled>Área</MenuItem>
                                            {
                                                gruposList.map((grupo, index) => {
                                                    return (<MenuItem key={grupo.doc_id} value={index}>{grupo.nome}</MenuItem>);
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={4} xs={5} sm={5}>
                                    <FormControl fullWidth={true}>
                                        <Select
                                            align="center"
                                            id="select-rpas"
                                            value={selectedRpaName === undefined ? 'selecione' : selectedRpaName}
                                            onChange={(event) => handleChangeSelectedRpaName(event.target.value)}
                                        >
                                            <MenuItem value={'selecione'} disabled>RPA</MenuItem>
                                            {
                                                rpaNameList.map((rpa_name) => {
                                                    return (<MenuItem key={rpa_name} value={rpa_name}>{rpa_name}</MenuItem>);
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '4vmin'}}>
                                <Grid item md={6} xs={6} sm={6} lg={6} style={{ textAlign:'center' }}>
                                    <Button onClick={() => handleClickOk()} color="primary" variant="contained">
                                        CALCULAR RPA SELECIONADO
                                    </Button>
                                </Grid>

                                <Grid item md={6} xs={6} sm={6} lg={6} style={{ textAlign:'center' }}>
                                    <Button onClick={() => handleClickCalcularTotal()} color="secondary" variant="contained">
                                        CALCULAR ÁREA SELECIONADA
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            
            {analyticsTotalData !== undefined &&
                <React.Fragment>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '1vmin' }} spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Card>
                                <CardContent>
                                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="h6" color="secondary" align="center">
                                                Tempo humano economizado no período selecionado
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="subtitle1" color="secondary" align="center">
                                                {secondsToHms(analyticsTotalData.total)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }

            {analyticsData !== undefined &&
                <React.Fragment>
                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '1vmin' }} spacing={2}>
                        <Grid item xs={12} sm={8} md={4}>
                            <Card>
                                <CardContent>
                                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                                        <Typography variant="h6" color="primary">
                                            RPA
                                        </Typography>
                                    </Grid>
                                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Typography variant="subtitle1" color="secondary" align="center">
                                                Tempo de processo no período: {secondsToTime(analyticsData.tempo_processamentos.total)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Typography variant="subtitle1" color="secondary" align="center">
                                                Tempo médio por processo: {analyticsData.processamentos.media > 0 ? secondsToTime(analyticsData.tempo_processamentos.media / analyticsData.processamentos.media) : "0"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Typography variant="subtitle1" color="secondary" align="center">
                                                Quantidade de processos no período: {analyticsData.processamentos.total}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                            <Typography variant="subtitle1" color="secondary" align="center">
                                                Quantidade média de processos por execução: {analyticsData.processamentos.media}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '1vmin' }} spacing={2}>
                        <Grid item xs={12} sm={8} md={4}>
                            <Card>
                                <CardContent>
                                    <Grid container alignContent="center" alignItems="center" justifyContent="center">
                                        <Typography variant="h6" color="primary">
                                            Humano
                                        </Typography>
                                    </Grid>
                                    <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="input-tempo-medio">Tempo por processo (em segundos)</InputLabel>
                                                <Input id="input-tempo-medio" type="number"
                                                    value={tempoDeProcessoHumano !== undefined ? tempoDeProcessoHumano : ""}
                                                    onChange={(event) => handleChangeTempoHumano(event.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {tempoDeProcessoHumano === undefined ?
                                            <Grid container alignContent="center" alignItems="center" justifyContent="center">
                                                <Typography variant="subtitle1" color="primary">
                                                    Adicione o tempo de processo humano para ver mais informações.
                                                </Typography>
                                            </Grid>
                                            :
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} md={12} style={{ marginTop: '3vmin' }}>
                                                    <Typography variant="subtitle1" color="secondary" align="center">
                                                        Tempo de processo no período: {secondsToTime(analyticsData.processamentos.total * tempoDeProcessoHumano)}
                                                    </Typography>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    {analyticsData.processamentos.dados !== undefined &&
                        <Grid container alignContent="center" alignItems="center" justifyContent="center" style={{ marginTop: '1vmin' }} spacing={2}>
                            <Grid item xs={12} sm={8} md={4}>
                                <Card className={classes.cardTable}>
                                    <CardContent>
                                        <Grid container alignContent="center" alignItems="center" justifyContent="center">
                                            <Typography variant="h6" color="primary">
                                                Execuções por dia
                                            </Typography>
                                        </Grid>
                                        <Grid container alignContent="center" alignItems="center" justifyContent="center" spacing={1}>
                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Data</TableCell>
                                                            <TableCell>Qtd. Processos</TableCell>
                                                            <TableCell>Tempo humano</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {analyticsData.processamentos.dados.map((row) => (
                                                            <TableRow hover key={format(row.data, "dd/MM/yyyy")}>
                                                                <TableCell>{format(row.data, "dd/MM/yyyy")}</TableCell>
                                                                <TableCell>{row.processados}</TableCell>
                                                                <TableCell>{tempoDeProcessoHumano !== undefined ? secondsToTime(row.processados * tempoDeProcessoHumano) : "?"}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value })
    };
}

export default connect(null, mapDispatchToProps)(AnalyticsTempo);
