import React, { useEffect } from "react";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import RpaDashboard from "../components/RpaDashboard";
import RpaList from "../components/RpaList";

function Rpas(props) {

	useEffect(() => {
		props.setRpa("page_title", "PAINEL RPA");
	}, [props]);

	if(props.user === undefined || props.user_data === undefined)
		return <React.Fragment></React.Fragment>

	return (
		<React.Fragment>
			<CssBaseline />
			{props.selected_rpa !== undefined ? 
				<RpaDashboard />
			:
				<RpaList />
			}
		</React.Fragment>
	);
}


function mapDispatchToProps(dispatch) {
    return {
        setRpa: (key, value) => dispatch({ type: "SET_RPA", key, value }),
    };
}

function mapStateToProps(state) {
	return {
		selected_rpa: state.rpa.selected_rpa,
		user_data: state.rpa.user_data,
		user: state.auth.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Rpas);
